import { ERROR_MESSAGE_STYLE } from "../TrackingStore/types";

export interface Settings {
  shop: string;
  multipleItemsPerRequest: boolean;
  requireImages: boolean;
  proxyPath: string;
  portalUrl: string;
  portalBaseUrl: string;
  storeUrl: string;
  direction: Direction;
  hasQuestions: boolean;
  customization: Customization;
  isEnabledExchangeResolutions: boolean;
  isEnabledReturnResolutions: boolean;
  isEnabledWhiteLabel: boolean;
  requestCustomersConfirmPolicy: boolean;
  giftReturnsEnabled: boolean;
  isEnabledPortalRmas: boolean;
  autoSelectSamePolicy?: boolean;
  isBxGyEligible: boolean;
  isBxGyFeatureEnabled: boolean;
  trackingPortalSupport?: boolean;
  isBrowseByCollectionActive: boolean;
  instantExchangeSettings: {
    daysToCapture: number;
    isRequired: boolean;
  },
  switchToExchangeSetting: SwitchToExchangeSetting;
  enablePortalCheckout: boolean;
  usePreAuthorization: boolean;
  refundToBankSetting: RefundToBankSetting;
  isEnabledAnyToAnyExchangesFeature: boolean;
  isEnabledShopNowFeature: boolean;
  storefrontPublicAccessToken: string | null;
  hidePricesForQuasiVariants: boolean;
}

export enum S2EIncentiveType {
  FIXED_PRICE = 'FIXED_PRICE',
  PERCENTAGE = 'PERCENTAGE'
}

export enum S2EIncentiveVirtualCurrency {
  GIFT_CARD = 'GIFT_CARD',
  STORE_CREDITS = 'STORE_CREDITS'
}

export interface SwitchToExchangeSetting {
  is_active: boolean;
  incentive_amount?: number;
  incentive_currency?: string;
  incentive_type?: S2EIncentiveType;
  incentive_virtual_currency?: S2EIncentiveVirtualCurrency;
}

export interface RefundToBankSetting {
  showFirstName: boolean,
  requireFirstName: boolean,
  showLastName: boolean,
  requireLastName: boolean,
  showBranchName: boolean,
  requireBranchName: boolean,
  showBranchAddress: boolean,
  requireBranchAddress: boolean,
  showComment: boolean,
  requireComment: boolean,
}

export enum Direction {
  RTL = 'rtl',
  LTR = 'ltr'
}

export interface Customization {
  logo: string | null;
  portal_css?: string | null;
  min_embedded_offset_top: number;
  skuDisplay: boolean;
  variantSelection: string;
  displayVertical: boolean;
  actionSelection: ActionSelection;
  error: boolean;
  showActionDescription: boolean;
  showOrderTrackingETA: boolean;
  showPaymentBlockFirst: boolean;
  showReducedPricePercentage: boolean;
  colors: {
    font: string,
    title_color: string,
    text_color: string,
    error_color: string,
    input_text_color: string,
    input_background_color: string,
    button_text_color: string,
    button_background_color: string,
  },
  portalPosition: PortalPosition,
  margins: {
    top: number,
    bottom: number,
    container: number
  }
  borderRadius: {
    portal: number,
    button: number,
    elements: number
  },
  trackingErrorMessageStyle: ERROR_MESSAGE_STYLE,
  background: {
    returnPortal: {
      type: PortalBackgroundType,
      value: string,
      size: PortalBackgroundSize,
      color: string,
      opacity: number
    },
    trackingPortal: {
      type: PortalBackgroundType,
      value: string,
      size: PortalBackgroundSize,
      color: string,
      opacity: number
    }
  },
  stickyFooter: {
    color: string,
    opacity: number
  }
  policyRuleDisplay: PolicyRuleDisplay,
	s2eContent: S2EContent,
  originalFonts: string;
}

export interface S2EContent {
	type: S2EContentType,
	items: Partial<Record<'image' | 'link', string>>[]
}

export enum OptionsForVariantsView {
    VARIANTS_VIEW_BOTH_OPTIONS = 'VARIANTS_VIEW_BOTH_OPTIONS',
    VARIANTS_VIEW_BOTH_ITEMS = 'VARIANTS_VIEW_BOTH_ITEMS',
    VARIANTS_VIEW_OPTIONS = 'VARIANTS_VIEW_OPTIONS',
    VARIANTS_VIEW_ITEMS = 'VARIANTS_VIEW_ITEMS'
}

export enum ActionSelection {
  ACTIONS_ON_ITEM_SELECTION = 'ACTIONS_ON_ITEM_SELECTION',
  ACTIONS_AFTER_SELECTING_ITEMS = 'ACTIONS_AFTER_SELECTING_ITEMS'
}

export enum PortalPosition {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT'
}

export enum PortalBackgroundType {
  IMAGE = 'IMAGE',
  COLOR = 'COLOR'
}

export enum PortalBackgroundSize {
  STRETCH = 'STRETCH',
  FIT = 'FIT',
  REPEAT = 'REPEAT'
}

export enum PolicyRuleDisplay {
  RESOLUTION = 'RESOLUTION',
  RETURN_METHOD = 'RETURN-METHOD'
}

export enum S2EContentType {
	ONE_RECTANGLE = 'ONE-RECTANGLE',
	TWO_RECTANGLES = 'TWO-RECTANGLES',
	THREE_RECTANGLES = 'THREE-RECTANGLES',
	TWO_SQUARES = 'TWO-SQUARES',
	FOUR_SQUARES = 'FOUR-SQUARES'
}

export const STOREFRONT_PUBLIC_ACCESS_TOKEN_COOKIE_NAME = 'storefront_public_access_token';

export const S2ELayoutItemsMap = {
	[S2EContentType.ONE_RECTANGLE]: 1,
	[S2EContentType.TWO_RECTANGLES]: 2,
	[S2EContentType.THREE_RECTANGLES]: 3,
	[S2EContentType.TWO_SQUARES]: 2,
	[S2EContentType.FOUR_SQUARES]: 4,
}

export interface SerializedData {
  settings: Settings;
}

export const IS_ENABLED_SHOP_NOW_FEATURE_STORAGE_KEY = '@returngo/portal/shop-now-enabled';
