import { observable } from 'mobx';

export const ADD_TO_CART_URLS = [
	'/cart/add.js', '/cart/add',
];
export const CART_URLS = [
	...ADD_TO_CART_URLS,
	'/cart',
	'/cart/change.js', '/cart/change',
	'/cart/update.js', '/cart/update',
	'/cart/clear.js', '/cart/clear',
];

export const GRAPHQL_URL = /^\/api\/\d{4}-\d{2}\/graphql\.json$/;

export const GRAPHQL_MUTATIONS = [
	'cartAttributesUpdate',
	'cartBuyerIdentityUpdate',
	'cartCreate',
	'cartDiscountCodesUpdate',
	'cartGiftCardCodesUpdate',
	'cartLinesAdd',
	'cartLinesRemove',
	'cartLinesUpdate',
	'cartMetafieldDelete',
	'cartMetafieldsSet',
	'cartNoteUpdate',
	'cartSelectedDeliveryOptionsUpdate'
];

export enum LIST_OF_COOKIES {
	EXCHANGE_CONTEXT = 'exchange_context',
	PORTAL_STATE = 'portal_state',
};

export const FORM_SELECTOR = 'form[action="/cart"]';
export const BUY_IT_NOW_SELECTOR = '[data-shopify="payment-button"] button';

export const ATTRIBUTE_ONCLICK = 'data-returngo-shop-now-click';
export const ATTRIBUTE_ONSUBMIT = 'data-returngo-shop-now-submit';
export const ATTRIBUTE_EVENT_TIMEOUT = 'data-returngo-shop-now-event-timeout';

export const ATTRIBUTE_VARIANT_ID = 'data-returngo-shop-now-variant-id';
export const ATTRIBUTE_QUANTITY = 'data-returngo-shop-now-quantity';


export const EVENT_CART_UPDATE = 'cart-update';
export const EVENT_CHECKOUT_REDIRECT = 'checkout';
export const EVENT_BUY_NOW = 'buy-now';
export const CHECKOUT_KEYWORDS = [
	'checkout',                             // English
	'caisse', 'paiement', 'payer',          // French
	'kasse', 'bezahlen', 'zahlung',         // German
	'cassa', 'pagamento', 'acquista',       // Italian
	'caja', 'pago', 'comprar',              // Spanish
	'caixa', 'pagamento', 'comprar',        // Portuguese
	'касса', 'оплата',                      // Russian
	'결제', '구매', '체크아웃',                 // Korean
	'结账', '付款', '购买',                   // Chinese (Simplified)
	'チェックアウト', '支払い', '購入',         // Japanese
	'קופה', 'תשלום', 'קנייה',               // Hebrew
	'betaling', 'køb',                      // Danish
	'kassa', 'afrekenen', 'betalen',        // Dutch
	'ödeme', 'satın al',                    // Turkish
	'касса', 'плащане', 'купуване',         // Bulgarian
	'kasse', 'betaling', 'kjøp',            // Norwegian
	'الدفع', 'شراء',                        // Arabic
	'kasa', 'płatność', 'zakup',            // Polish
	'ταμείο', 'πληρωμή', 'αγορά',           // Greek
	'pokladňa', 'platba', 'kúpiť',          // Slovak
	'pénztár', 'fizetés', 'vásárlás',       // Hungarian
	'blagajna', 'plačilo', 'nakup',         // Slovenian
	'pokladna', 'platba', 'koupit'          // Czech
];

/** Value for source param of the event data communicated between portal and parent windows */
export const EVENT_SOURCE = 'returngo';

/**
 * List of event actions dispatched from iframe portal to parent window
 */
export enum EVENT_ACTION {
	REDIRECT = 'redirect',      // Fired when redirect is requested from portal
	GET_CART = 'get-cart',      // Fired when cart data is requested from portal
	CLEAR_CART = 'clear-cart',  // Fired when cart clearing is requested from portal
	CART_DATA = 'cart-data',    // Fired when cart data is returned back to portal
}


export interface CartData {
	total: number;
	currency: string;
	items: CartItem[];
	cart_level_discount_applications: DiscountApplication[];
}

export interface CartItem {
	id: number | string;
	variant_id: number | string | null;
	product_id: number | string;
	quantity: number;
	title: string;
	original_price: number;
	discounted_price: number;
	discounts: CartItemDiscount[];
	sku: string;
	image: string;
	product_title: string;
	variant_title: string;
	options_with_values: { name: string; value: string }[];
	quantity_rule?: CartItemQuantityRule;
}

export interface CartItemDiscount {
	amount: number;
	title: string;
	target_type: IDiscountAllocationTargetType;
}

export interface CartItemQuantityRule {
	min: number;
	max: number | null;
	increment: number;
}

export interface DiscountApplication {
	title: string;
	target_type: IDiscountAllocationTargetType;
	total_allocated_amount: number;
}

export interface ICurrencyAmount {
	amount: number;
	currencyCode: string;
}

export interface IAppliedGiftCard {
	id: string;
	amountUsed: ICurrencyAmount;
	balance: ICurrencyAmount;
}

export interface IProductVariant {
	id: string;
	sku: string;
	image: {
		url: string;
	};
	title: string;
	price: ICurrencyAmount;
	unitPrice: ICurrencyAmount;
	selectedOptions: {
		name: string;
		value: string;
	}[];
	availableForSale: boolean;
	quantityAvailable: number;
	requiresShipping: boolean;
	currentlyNotInStock: boolean;
	quantityRule: {
		increment: number;
		maximum: number;
		minimum: number;
	};
	product: {
		id: string;
		title: string;
	};
}

export enum IDiscountAllocationTargetType {
	LINE_ITEM = 'LINE_ITEM',
	SHIPPING_LINE = 'SHIPPING_LINE',
}

export interface IDiscountAllocation {
	title?: string;
	code?: string;
	discountedAmount: ICurrencyAmount;
	targetType: IDiscountAllocationTargetType;
}

export interface ICartLineItem {
	id: string;
	quantity: number;
	merchandise: IProductVariant;
	discountAllocations: IDiscountAllocation[];
	cost: {
		amountPerQuantity: ICurrencyAmount;
		subtotalAmount: ICurrencyAmount;
		totalAmount: ICurrencyAmount;
	};
}

export interface IDiscountCode {
	applicable: boolean;
	code: string;
}

export interface ICartCost {
	totalAmount: ICurrencyAmount;
	subtotalAmount: ICurrencyAmount;
	totalTaxAmount?: ICurrencyAmount;
	totalDutyAmount?: ICurrencyAmount;
}

export interface ICart {
	id: string;
	appliedGiftCards: IAppliedGiftCard[];
	lines: {
		edges: {
			node: ICartLineItem;
		}[];
		pageInfo: {
			hasNextPage: boolean;
			endCursor?: string;
		}
	};
	discountCodes: IDiscountCode[];
	discountAllocations: IDiscountAllocation[];
	cost: ICartCost;
}

export interface ICartQueryResponse {
	cart: ICart;
}

export interface ICartLinesQueryResponse {
	cart: Pick<ICart, 'lines'>;
}

export interface IUserError {
	field: string[] | null;
	message: string;
}

export type IMutationResponse<IMutationName extends string, IData extends object> = {
	[key in IMutationName]: IData & {
		userErrors?: IUserError[];
	}
}

export interface ICartCreateResponseData {
	cart: Pick<ICart, 'id'>
}

export interface ICartCreateResponse extends IMutationResponse<'cartCreate', ICartCreateResponseData> {
}

export interface ICartLinesAddResponseData {
	cart: Pick<ICart, 'id'>
}

export interface ICartLinesAddResponse extends IMutationResponse<'cartLinesAdd', ICartLinesAddResponseData> {
}

export interface ICartLinesRemoveResponseData {
	cart: Pick<ICart, 'id'>
}

export interface ICartLinesRemoveResponse extends IMutationResponse<'cartLinesRemove', ICartLinesRemoveResponseData> {
}

