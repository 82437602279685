const data = {
  'case_id': 15,
  'rma_status': 1,
  'rma_date': '0',
  'rma_code': 'FGHJ54AGAB',
  'shipping_instructions': 'RETURNMETHOD_INSTRUCTIONS1',
  'label_uri': "https://static.returngo.ai/dev-pub/labels/32e5f9de828c10cd5584cfaecf0afef4.pdf",
  'locator_url': "https://tools.usps.com/find-location.htm?locationType=po&serviceType=lbroretail&address=90210",
  'qr_code': "https://static.returngo.ai/dev-pub/qrs/usps_lbro9-QR.png",
  'qr_instructions': 'RETURNMETHOD_QR_INSTRUCTION1',
};

const HAPPY_RETURNS_OFFER = {
  "case_id": "15",
  "instant_credit": 0,
  "discount_code": null,
  "gift_card_code": null,
  "rma_status": 1,
  "rma_date": "2022-12-01T08:20:48.000000Z",
  "rma_code": "RMA10297",
  "shipping_instructions": "<div><strong>Drop items at Happy Returns\u00ae<br \/>No box or label needed.<\/strong><br\/><br\/>Refund initiated immediately in our store.<br\/><div style=\"text-align: center;\"><hr style=\"width: 50%;\" \/><\/div>Your closest location is 0 mile away.<br\/><br\/><div style=\"color: #a4a4a4;\">Staples 0746<br \/>2248 Broadway, New York<br \/><br \/>Mon: CLOSED<br \/>Tue - Sat: 7AM - 9PM<br \/>Sun: 9AM - 9PM<br \/>Mon: 10AM - 7PM<br \/>Tue - Sun: CLOSED<\/div><a href=\"https:\/\/locations.happyreturns.com?retailer=returngo-sandbox&distance=10&address=541%20Amsterdam%20Avenue%2C%2025%2C%20New%20York%2C%20NY%2C%2010024\">See all Return Bar\u00ae locations >><\/a><br\/><br\/>Show this code to a Returnista at the Return Bar.<br\/><br\/><div style=\"text-align: center;\"><img style=\"width: 200px;\" src=\"https:\/\/partner.happyreturns.com\/barcode\/qr?code=HREGTVSN\" \/><br\/><br\/>Express code: HREGTVSN<\/div><br\/><br\/><\/div>",
  "qr_instructions": null,
  "label_uri": null,
  "qr_code": null,
  "carrier_location_name": null,
  "locator_url": null,
  "packing_slip_uri": null,
  "instant_refund_url": null,
  "instant_refund_amount": null
};

export const fetch = (init?: RequestInit) => {
  const body = JSON.parse(init?.body as string ?? null);
  if (body) {
    /** if happe returns offer exists (id 3232) - return response for happy returns */
    const hasHappyReturnsOffer = body.order_products.some(prod => prod.offer.product_case_offer_id === 3232);
    if (hasHappyReturnsOffer) {
      return { data: HAPPY_RETURNS_OFFER };
    }
  }

  return { data };
}
