import { getCookie, getShop, isNotNullOrUndefined } from '../../utils/helpers';
import { STOREFRONT_PUBLIC_ACCESS_TOKEN_COOKIE_NAME } from '../../stores/SettingsStore/types';
import { IMutationResponse, IUserError } from './GQLService.types';

export default class GQLService {

	/**
	 * Sends a GraphQL request to the Storefront API.
	 *
	 * @param query - The GraphQL query string.
	 * @param variables - Optional variables to include in the request.
	 *
	 * @returns Promise<any>
	 * @private
	 */
	public static async graphQL<T>(query: string, variables?: any): Promise<T | null> {
		try {
			const response = await fetch(`https://${getShop()}/api/${SHOPIFY_STOREFRONT_API_VERSION}/graphql.json`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-Shopify-Storefront-Access-Token': getCookie(STOREFRONT_PUBLIC_ACCESS_TOKEN_COOKIE_NAME),
				},
				body: JSON.stringify({ query, variables })
			});
			const { data, errors } = await response.json();

			if (!isNotNullOrUndefined(data) || isNotNullOrUndefined(errors)) {
				console.log('[GQLService] Error:', errors);
				return null;
			}

			return data;
		} catch (error) {
			console.log('[GQLService] Error:', error);

			return null;
		}
	}

	/**
	 * Validates the result of a GraphQL mutation by checking for user errors.
	 *
	 * @template TMutationName The name of the mutation as a string.
	 * @template TData The shape of the data returned by the mutation.
	 * @param result The result of the mutation, which includes the mutation data and optional user errors.
	 * @param mutationName The name of the mutation to validate, corresponding to a key in the `result` object.
	 * @returns The mutation data (`TData`) if no user errors are present.
	 * @throws Error If user errors are found in the mutation response, an error is thrown with a detailed message.
	 */
	public static validateMutation<TMutationName extends string, TData extends object>(result: IMutationResponse<TMutationName, TData>, mutationName: TMutationName): TData {
		if (!isNotNullOrUndefined(result)) {
			throw new Error('[GQLService] Mutation result is empty');
		}
		if (mutationName in result && result[mutationName]?.userErrors?.length) {
			throw new Error(`[GQLService] ${
				GQLService.parseUserErrorsToString(result[mutationName].userErrors!)
			}`);
		}
		return result[mutationName];
	}

	/**
	 * Converts an array of user error objects into a human-readable string.
	 *
	 * @param userErrors An array of `IUserError` objects containing error details (messages and optionally, field paths).
	 * @returns A string that concatenates all error messages, formatted with field paths if available.
	 * @private
	 */
	private static parseUserErrorsToString(userErrors: IUserError[]): string {
		return userErrors
			.map(
				(error) =>
					`${error.message}${error.field ? ` (${error.field.join(' > ')})` : ''}`
			)
			.join('. ');
	}
}
