import {
	denormalizeShopifyGid,
	getCookie, getLocale,
	isNotNullOrUndefined,
	normalizeShopifyGid,
	safeDivision,
	setCookie
} from '../utils/helpers';
import {
	CartData,
	ICartCreateResponse,
	ICartLinesAddResponse, ICartLinesAddResponseData,
	ICartLinesQueryResponse, ICartLinesRemoveResponse, ICartLinesRemoveResponseData,
	ICartQueryResponse,
	IDiscountAllocationTargetType
} from './InStoreService/InStoreService.types';
import GQLService from './GQLService/GQLService';

export class CartService {

	/**
	 * Retrieves the store's cart ID.
	 * The Cart ID is stored in a cookie named 'cart' in the format '<cart_id>?key=<some_key>'.
	 *
	 * @param keepKey - A boolean indicating whether to retain the key portion of the Cart ID.
	 *                  If true, the returned Cart ID will include the '?key=<some_key>' part.
	 *                  If false, only the Cart ID (before the '?') is returned.
	 * @throws Error
	 * @returns string
	 */
	public static async getCartId(keepKey: boolean = false): Promise<string> {
		let cartId = getCookie('cart');

		if (!isNotNullOrUndefined(cartId)) {
			cartId = await CartService.createCart();
			setCookie('cart', cartId, 60);
		} else {
			cartId = decodeURIComponent(cartId);
		}

		if (!!cartId && !keepKey) {
			cartId = cartId.split('?')[0];
		}

		return cartId;
	}

	/**
	 * Creates a new cart for the store and returns its ID.
	 *
	 * @returns string
	 * @throws Error
	 */
	public static async createCart(): Promise<string> {
		const data = GQLService.validateMutation<'cartCreate', ICartLinesAddResponseData>(
			await GQLService.graphQL<ICartCreateResponse>(CartService.getCartCreateMutation(), {}),
			'cartCreate'
		);

		return denormalizeShopifyGid(data.cart.id, 'Cart');
	}

	/**
	 * Retrieves the store's cart.
	 *
	 * @returns Promise<CartData>
	 */
	public static async getCart(): Promise<CartData> {
		try {
			return await CartService.getCartGraphQL();
		} catch (e) {
			console.debug('[CartService] Unable to fetch cart using Storefront API, attempting Cart.js API...');

			return await CartService.getCartAjax();
		}
	}

	/**
	 * Retrieves the store's cart using Storefront GraphQL API.
	 * @private
	 */
	private static async getCartGraphQL(): Promise<CartData> {
		const cartId = await CartService.getCartId();

		const data = await GQLService.graphQL<ICartQueryResponse>(CartService.getCartQuery(cartId));

		if (!isNotNullOrUndefined(data)) {
			throw new Error('[CartService] Unable to retrieve cart data.');
		}

		const { cart } = data;

		while (cart.lines.pageInfo.hasNextPage) {
			const query = CartService.getCartLinesQuery(cartId, cart.lines.pageInfo.endCursor);
			const { cart: { lines } } = await GQLService.graphQL<ICartLinesQueryResponse>(query);

			cart.lines.edges.push(...lines.edges);
			cart.lines.pageInfo = lines.pageInfo;
		}

		const cartData = {
			total: cart.cost.totalAmount.amount,
			currency: cart.cost.totalAmount.currencyCode,
			items: cart.lines.edges.map(({ node: cartLine }) => ({
				id: cartLine.id,
				variant_id: denormalizeShopifyGid(cartLine.merchandise.id, 'ProductVariant'),
				product_id: denormalizeShopifyGid(cartLine.merchandise.product.id, 'Product'),
				quantity: cartLine.quantity,
				title: cartLine.merchandise.product.title,
				sku: cartLine.merchandise.sku,
				image: cartLine.merchandise.image.url,
				product_title: cartLine.merchandise.product.title,
				variant_title: cartLine.merchandise.title,
				options_with_values: cartLine.merchandise.selectedOptions,
				original_price: cartLine.merchandise.price.amount,
				discounted_price: safeDivision(cartLine.cost.totalAmount.amount, cartLine.quantity, cartLine.cost.totalAmount.amount),
				discounts: cartLine.discountAllocations
					.filter((discountAllocation) => discountAllocation.targetType !== IDiscountAllocationTargetType.SHIPPING_LINE)
					.map((discountAllocation) => ({
						amount: discountAllocation.discountedAmount.amount,
						title: discountAllocation?.code ?? discountAllocation?.title,
						target_type: discountAllocation.targetType
					})),
				quantity_rule: {
					min: cartLine.merchandise.quantityRule.minimum,
					max: cartLine.merchandise.quantityRule.maximum,
					increment: cartLine.merchandise.quantityRule.increment,
				}
			})),
			cart_level_discount_applications: cart.discountAllocations
				.filter((discountAllocation) => discountAllocation.targetType !== IDiscountAllocationTargetType.SHIPPING_LINE)
				.map((discountAllocation) => ({
					title: discountAllocation?.code ?? discountAllocation?.title,
					total_allocated_amount: discountAllocation.discountedAmount.amount,
					target_type: discountAllocation.targetType
				}))
		} satisfies CartData;

		return cartData;
	}

	/**
	 * Retrieves the store's cart using Cart.js AJAX API.
	 *
	 * TODO: Remove support of Cart.js API after all merchants accept new Storefront API permissions
	 *
	 * @private
	 */
	private static async getCartAjax(): Promise<CartData> {
		const cart = await fetch((window.Shopify?.routes?.root ?? '/')  + 'cart.js').then(response => response.json());

		const cartData = {
			total: cart.total_price / 100,
			currency: cart.currency,
			items: cart.items.map((cartItem) => ({
				id: cartItem.id,
				variant_id: cartItem.variant_id,
				product_id: cartItem.product_id,
				quantity: cartItem.quantity,
				title: cartItem.title,
				sku: cartItem.sku,
				image: cartItem.image,
				product_title: cartItem.product_title,
				variant_title: cartItem.variant_title,
				options_with_values: cartItem.options_with_values,
				original_price: cartItem.original_price / 100,
				discounted_price: cartItem.discounted_price / 100,
				discounts: cartItem.discounts.map((discount) => ({
					title: discount.title,
					amount: discount.amount / 100,
					target_type: IDiscountAllocationTargetType.LINE_ITEM
				}))
			})),
			cart_level_discount_applications: cart.cart_level_discount_applications.map((discountAllocation) => ({
				title: discountAllocation.title,
				total_allocated_amount: discountAllocation.total_allocated_amount / 100,
				target_type: discountAllocation.target_type.toUpperCase()
			}))
		} satisfies CartData;

		return cartData;
	}

	/**
	 *
	 * Removes the cart by deleting the 'cart' cookie.
	 *
	 * @returns Promise<void>
	 */
	public static async clearCart(): Promise<void> {
		try {
			await CartService.clearCartGraphQL();
		} catch (error) {
			console.debug('[CartService] Unable to clear cart using GraphQL API, attempting Cart.js API...');

			await CartService.clearCartAjax();
		}
	}

	/**
	 * Removes all items from the cart.
	 *
	 * @private
	 */
	private static async clearCartGraphQL()
	{
		const cartId = await CartService.getCartId(true);

		const { items } = await CartService.getCart();

		return GQLService.validateMutation<'cartLinesRemove', ICartLinesRemoveResponseData>(
			await GQLService.graphQL<ICartLinesRemoveResponse>(CartService.getCartLinesRemoveMutation(), {
				cartId: normalizeShopifyGid(cartId, 'Cart'),
				lineIds: items.map((item) => item.id)
			}),
			'cartLinesRemove'
		);
	}

	/**
	 * Removes all items from the cart using Cart.js AJAX API.
	 * @private
	 */
	private static async clearCartAjax()
	{
		return await fetch((window.Shopify?.routes?.root ?? '/') + 'cart/clear.js', {
			method: 'POST'
		}).then(response => response.json());
	}

	/**
	 * Adds an item to the cart.
	 *
	 * @param variantId
	 * @param quantity
	 */
	public static async addItemToCart(variantId: string | number, quantity: number = 1) {
		try {
			return await CartService.addItemToCartGraphQL(variantId, quantity);
		} catch (error) {
			console.debug('[CartService] Unable to add item to cart using GraphQL API, attempting Cart.js API...');

			return await CartService.addItemToCartAjax(variantId, quantity);
		}
	}

	/**
	 * Adds an item to the cart using Storefront GraphQL API.
	 *
	 * @param variantId
	 * @param quantity
	 * @private
	 */
	private static async addItemToCartGraphQL(variantId: string | number, quantity: number = 1)
	{
		const cartId = await CartService.getCartId(true);

		return GQLService.validateMutation<'cartLinesAdd', ICartLinesAddResponseData>(
			await GQLService.graphQL<ICartLinesAddResponse>(CartService.getCartLinesAddMutation(), {
				cartId: normalizeShopifyGid(cartId, 'Cart'),
				lines: [{
					merchandiseId: normalizeShopifyGid(variantId, 'ProductVariant'),
					quantity: quantity
				}]
			}),
			'cartLinesAdd'
		);
	}

	/**
	 * Adds an item to the cart using Cart.js AJAX API.
	 *
	 * TODO: Remove support of Cart.js API after all merchants accept new Storefront API permissions
	 *
	 * @param variantId
	 * @param quantity
	 * @private
	 */
	private static async addItemToCartAjax(variantId: string | number, quantity: number = 1)
	{
		return await fetch((window.Shopify?.routes?.root ?? '/') + 'cart/add.js', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				items: [{
					id: variantId,
					quantity: quantity
				}]
			})

		}).then(response => response.json());
	}

	/**
	 * Returns cart query
	 * @param cartId
	 * @returns string
	 * @private
	 */
	private static getCartQuery(cartId: string): string {
		return `query @inContext(language: ${getLocale().toUpperCase()}) {
			cart(id: "${normalizeShopifyGid(cartId, 'Cart')}") {
				id
				appliedGiftCards {
					id
					amountUsed {
						amount
						currencyCode
					}
					balance {
						amount
						currencyCode
					}
				}
				${CartService.getCartLinesAdditionToQuery()}
				discountCodes {
					applicable
					code
				}
				discountAllocations {
					... on CartAutomaticDiscountAllocation {
					    title
					}
					... on CartCodeDiscountAllocation {
					    code
					}
					discountedAmount {
						amount
						currencyCode
					}
					targetType
				}
				cost {
					totalAmount {
						amount
						currencyCode
					}
					subtotalAmount {
						amount
						currencyCode
					}
					totalTaxAmount {
						amount
						currencyCode
					}
					totalDutyAmount {
						amount
						currencyCode
					}
				}
			}
		}`;
	}

	/**
	 * Returns cart lines query
	 * @param cartId
	 * @param endCursor
	 * @returns string
	 * @private
	 */
	private static getCartLinesQuery(cartId: string, endCursor?: string): string {
		return `query {
			cart(id: "${normalizeShopifyGid(cartId, 'Cart')}") {
				${CartService.getCartLinesAdditionToQuery(endCursor)}
			}
		}`
	}

	/**
	 * Returns cart lines addition to query
	 * @param endCursor
	 * @returns string
	 * @private
	 */
	private static getCartLinesAdditionToQuery(endCursor?: string): string {
		return `lines(first: 1${isNotNullOrUndefined(endCursor) ? `, after: "${endCursor}"` : ''}) {
			edges {
				node {
					id
					quantity
					merchandise {
					... on ProductVariant {
							id
							sku
							image {
								url
							}
							title
							price {
								amount
								currencyCode
							}
							unitPrice {
								amount
								currencyCode
							}
							selectedOptions {
								name
								value
							}
							availableForSale
							quantityAvailable
							requiresShipping
							currentlyNotInStock
							quantityRule {
								increment
								maximum
								minimum
							}
							product {
								id
								title
							}
						}
					}
					discountAllocations {
						... on CartAutomaticDiscountAllocation {
					        title
					    }
					    ... on CartCodeDiscountAllocation {
					        code
					    }
					    discountedAmount {
					        currencyCode
					        amount
					    }
						targetType
					}
					cost {
						amountPerQuantity {
							amount
							currencyCode
						}
						subtotalAmount {
							amount
							currencyCode
						}
						totalAmount {
							amount
							currencyCode
						}
					}
				}
			}
			pageInfo {
				endCursor
				hasNextPage
			}
		}`;
	}

	/**
	 * Returns cart lines add mutation
	 * @returns string
	 * @private
	 */
	private static getCartLinesAddMutation(): string {
		return `mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
			cartLinesAdd(cartId: $cartId, lines: $lines) {
				cart {
				    id
				}
				userErrors {
				    field
				    message
				}
			}
		}`;
	}

	/**
	 * Returns cart create mutation
	 * @returns string
	 * @private
	 */
	private static getCartCreateMutation(): string {
		return `mutation cartCreate($input: CartInput) {
			cartCreate(input: $input) {
				cart {
					id
				}
				userErrors {
				    field
				    message
				}
			}
		}`;
	}

	/**
	 * Returns cart create mutation
	 * @returns string
	 * @private
	 */
	private static getCartLinesRemoveMutation(): string {
		return `mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
            cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
				cart {
					id
				}
				userErrors {
				    field
				    message
				}
			}
		}`;
	}
}
