import { embeddedTools } from './embedded-tools';
import HttpService from './services/HttpService';
import {
  getCookie,
  getCustomerId,
  getDiscountCodeCookieName,
  getDiscountNoCodeValue,
  getShop,
  getCustomerIdCookieName,
  isEmbedded,
  setCookie,
  isAtPage,
  getPortalType, removeCookie,
  isReturnPortal,
  getReturnGoShopNow, setReturnGoShopNow, isNotNullOrUndefined, getLocalStorageItem
} from './utils/helpers';
import { setIsolationModeIsEnabled } from './utils/isolation';
import { IS_ENABLED_SHOP_NOW_FEATURE_STORAGE_KEY } from './stores/SettingsStore/types';

const RETURN_GO_CONTAINER = "ReturngoPortalEmbedding";

declare var __webpack_hash__: string | undefined;
const hash = MODE === 'production' ? __webpack_hash__ + '.' : '';

resetDiscountCodeCookieIfTriggered();
let returnGoCanBeRun = getReturnGoCanBeRun(document);

if (returnGoCanBeRun && shouldAnyScriptBeLoaded()) {
  setCanBeRun(document, false);
  const controller = new AbortController();
  const signal = controller.signal;
  const requestTimeout = setTimeout(() => controller.abort(), +API_TIMEOUT);
  HttpService.post(`${API_DOMAIN}/api/v2/shopify/integration`, {
    body: JSON.stringify({
      referer: window.location.href,
      shop: getShop(),
      customer_id: shouldCustomerInfoBeRequested() ? getCustomerId() : undefined,
      portal_type: getPortalType()
    }),
    signal,
  }).then(({ data, error }) => {

    /** Check if Clarity enabled and if it is portal */
    if ((data?.is_clarity_enabled ?? false) && isReturnPortal()) {
      embeddedTools.embedClarity();
    }

    const isIsolationModeEnabled = data?.portal_isolation_mode === 'shadow_dom';
    setIsolationModeIsEnabled(isIsolationModeEnabled);

    if (error || data.integration_enabled) {
      if (shouldAssistantScriptBeLoaded()) {
        loadHelperScripts();
        loadVendors();
        loadUI();
        loadScript(`${ASSETS_DOMAIN}/assistant.${hash}min.js`);
      }
    }
    if (!error && data.integration_enabled) {
      clearTimeout(requestTimeout);

      window.returnGoIntegrationData = data;

      if (shouldDiscountScriptBeLoaded()) {
        loadScript(`${ASSETS_DOMAIN}/discount.${hash}min.js`);
      }

      if (shouldAccountScriptBeLoaded()) {
        loadHelperScripts();
        loadVendors();
        loadUI();
        loadScript(`${ASSETS_DOMAIN}/account.${hash}min.js`);
      }

      if (shouldCartScriptBeLoaded()) {
        loadHelperScripts();
        loadVendors();
        loadUI();
        loadScript(`${ASSETS_DOMAIN}/cart.${hash}min.js`);
      }
    }
  });
}

// Load Shop Now script independently, it doesn't require any data from the server
if (returnGoCanBeRun && shouldInStoreScriptBeLoaded()) {
  loadHelperScripts();
  loadVendors();
  loadUI();
  const inStoreScriptUrl = `${ASSETS_DOMAIN}/instore.${hash}min.js`;
  loadScript(inStoreScriptUrl);
}

// Initialize ReturnGo Shop Now object for all pages, because it contains methods for testing Shop Now
setReturnGoShopNow();

function loadVendors() {
  loadScript(`${ASSETS_DOMAIN}/vendors.${hash}min.js`);
}

function loadUI() {
  loadScript(`${ASSETS_DOMAIN}/ui.${hash}min.js`);
}

function loadHelperScripts() {
  if (!window.Currency) {
    loadScript('https://cdn.shopify.com/s/javascripts/currencies.js');
  }
}

function loadScript(src) {
  const script = document.createElement('script');
  script.src = `${src}?shop=${getShop()}`;
  script.async = true;
  document.head.appendChild(script);
}

function shouldAssistantScriptBeLoaded(): boolean {
  return getShop() && (/.*(\?returns|return-assistant=1).*/.test(window.location.href) || isEmbedded());
}

function shouldDiscountScriptBeLoaded(): boolean {
  return getShop()
    && getCustomerId()
    && getCookie(getDiscountCodeCookieName()) !== getDiscountNoCodeValue()
    && (
      !getCookie('discount_code')
      || getCookie(getDiscountCodeCookieName()) !== getCookie('discount_code')
    );
}

function shouldCartScriptBeLoaded(): boolean {
  return getShop() && getCustomerId() && (isAtPage('cart') || isEmbedded('ReturngoCartEmbedding'));
}

function shouldAccountScriptBeLoaded(): boolean {
  return getShop() && getCustomerId() && (isAtPage('account') || isEmbedded('ReturngoAccountEmbedding'));
}

function shouldInStoreScriptBeLoaded(): boolean {
  const isEnabledShopNowFeature = getLocalStorageItem(IS_ENABLED_SHOP_NOW_FEATURE_STORAGE_KEY) === '1';
  const shopNowObject = getReturnGoShopNow();
  return isEnabledShopNowFeature && getShop() && !shouldAssistantScriptBeLoaded() && (isNotNullOrUndefined(shopNowObject?.poc) || !isNotNullOrUndefined(shopNowObject));
}

function shouldAnyScriptBeLoaded() {
  return shouldAssistantScriptBeLoaded()
    || shouldDiscountScriptBeLoaded()
    || shouldCartScriptBeLoaded()
    || shouldAccountScriptBeLoaded();
}

function shouldCustomerInfoBeRequested() {
  return shouldCartScriptBeLoaded()
    || shouldAccountScriptBeLoaded()
    || getCookie(getDiscountCodeCookieName()) === null;
}

function resetDiscountCodeCookieIfTriggered() {
  if (window.location.href.includes('/discount')
    || shouldAssistantScriptBeLoaded()
    || shouldAccountScriptBeLoaded()
    || shouldCartScriptBeLoaded()
    || getCookie(getCustomerIdCookieName()) !== String(getCustomerId())
  ) {
    removeCookie(getDiscountCodeCookieName());
  }
  setCookie(getCustomerIdCookieName(), String(getCustomerId()), 60 * 24);
}


function getReturnGoCanBeRun(document: Document): Boolean {
  let canBeRun;
  if (isEmbedded()) {
    // get the state from the container div
    const returnGoContainer = <HTMLElement>document.getElementById(RETURN_GO_CONTAINER);
    // pull the data attribute from the div. It might be null or a string value, so JSON.parse handles this
    canBeRun = returnGoContainer.getAttribute('data-run_scripts') ? JSON.parse(returnGoContainer.getAttribute('data-run_scripts')) : true;
  } else {
    // no container div, get state from window scope.
    canBeRun = window.returnGoCanBeRun;
  }
  return canBeRun !== false;

}

function setCanBeRun(document: Document, canBeRun: Boolean): void {
  let returnGoContainer = <HTMLElement>document.getElementById(RETURN_GO_CONTAINER);
  isEmbedded() ? returnGoContainer.dataset.run_scripts = canBeRun.toString() : window.returnGoCanBeRun = false;
}
