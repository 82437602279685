import { ERROR_CODE } from './types';

export const transformCodeToMessage = (code?: ERROR_CODE): string => {
  switch (code) {
    case ERROR_CODE.ORDER_NOT_FOUND: {
      return 'ORDER_NOT_FOUND';
    }
    case ERROR_CODE.ALREADY_REFUNDED: {
      return 'ALREADY_REFUNDED';
    }
    case ERROR_CODE.ALREADY_CLOSED: {
      return 'ALREADY_CLOSED';
    }
    case ERROR_CODE.NOT_FULFILLED: {
      return 'NOT_FULFILLED';
    }
    case ERROR_CODE.TOO_MANY_OFFERS: {
      return 'TOO_MANY_OFFERS';
    }
    case ERROR_CODE.POLICY_EXPIRED: {
      return 'POLICY_EXPIRED';
    }
    case ERROR_CODE.ERR_RETURN_VALUE_TOO_HIGH: {
      return 'ERR_RETURN_VALUE_TOO_HIGH';
    }
    case ERROR_CODE.ERR_ORDER_ALREADY_CANCELLED: {
      return 'ERR_ORDER_ALREADY_CANCELLED';
    }
    case ERROR_CODE.ERR_ORDER_ALREADY_PENDING_FOR_CANCELLATION: {
      return 'ERR_ORDER_ALREADY_PENDING_FOR_CANCELLATION';
    }

    case ERROR_CODE.ERR_PAYMENT_PRE_AUTHORIZATION_FAILED: {
      return 'ERR_PAYMENT_PRE_AUTHORIZATION_FAILED';
    }

  }
  return 'UNKNOWN';
};
