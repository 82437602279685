const data = {
  integration_enabled: true,
  credits: 15,
  currency_code: 'USD',
  discount_code: 'TEST',
  portal_mode: {
    returns: true,
    // to disable tracking, set this to false.
    tracking: true,
    portal_rmas: true,
  }
};

export const fetch = (init?: RequestInit) => {
  return { data };
}
