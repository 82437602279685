const data = {
  '12345': {
    options: [
      { name: 'color', values: ['red', 'blue', 'pink']},
      { name: 'size', values: ['extra-small', 'small', 'medium', 'large', 'extra-large']}
    ],
    alternatives: [
      {
        id: '98765',
        title: 'Red/Small',
        options: [{ name: 'color', value: 'red'}, { name: 'size', value: 'small'}],
        price_allowed: true,
        inventory_allowed: true,
        inventory_quantity: 20,
      },
      {
        id: '76543',
        title: 'Red/Large',
        options: [{ name: 'color', value: 'red'}, { name: 'size', value: 'large'}],
        price_allowed: true,
        inventory_allowed: false,
        inventory_quantity: 20,
      },
      {
        id: '65432',
        title: 'Blue/Large',
        options: [{ name: 'color', value: 'blue'}, { name: 'size', value: 'large'}],
        price_allowed: true,
        inventory_allowed: true,
        inventory_quantity: 20,
      },
      {
        id: '12345',
        title: 'Blue/Extra-small',
        options: [{ name: 'color', value: 'blue'}, { name: 'size', value: 'extra-small'}],
        price_allowed: true,
        inventory_allowed: true,
        inventory_quantity: 20,
      },
      {
        id: '12346',
        title: 'Red/Extra-small',
        options: [{ name: 'color', value: 'red'}, { name: 'size', value: 'extra-small'}],
        price_allowed: true,
        inventory_allowed: true,
        inventory_quantity: 20,
      },
      {
        id: '65433',
        title: 'Blue/Extra-large',
        options: [{ name: 'color', value: 'blue'}, { name: 'size', value: 'extra-large'}],
        price_allowed: true,
        inventory_allowed: true,
        inventory_quantity: 20,
      },
      {
        id: '65434',
        title: 'Red/Extra-large',
        options: [{ name: 'color', value: 'red'}, { name: 'size', value: 'extra-large'}],
        price_allowed: true,
        inventory_allowed: true,
        inventory_quantity: 20,
      },
    ]
  }
};

export const fetch = (init?: RequestInit) => {
  return { data };
}
