import {
	getCookie,
	getLocalStorageItem,
	isFunction,
	isInIFrame,
	removeCookie,
	removeLocalStorageItem,
	setCookie,
	setLocalStorageItem
} from '../../utils/helpers';
import {
	ADD_TO_CART_URLS,
	ATTRIBUTE_EVENT_TIMEOUT,
	ATTRIBUTE_ONCLICK,
	ATTRIBUTE_ONSUBMIT,
	ATTRIBUTE_QUANTITY,
	ATTRIBUTE_VARIANT_ID,
	BUY_IT_NOW_SELECTOR,
	CART_URLS,
	CartData,
	CHECKOUT_KEYWORDS,
	EVENT_ACTION,
	EVENT_BUY_NOW,
	EVENT_CART_UPDATE,
	EVENT_CHECKOUT_REDIRECT,
	EVENT_SOURCE,
	GRAPHQL_MUTATIONS,
	GRAPHQL_URL,
	LIST_OF_COOKIES
} from './InStoreService.types';
import { CartService } from '../CartService';
import { SerializedData } from '../../stores/RootStore/types';

export default class InStoreService {

	/**
	 * This method must be used to retrieve in-store cart data.
	 * Since stores are allowed to override the window.returnGoShopNow.getCart method, we need to attempt using it.
	 *
	 * @returns Promise<CartData>
	 */
	public static async getCart(): Promise<CartData> {
		if (isInIFrame()) {
			return new Promise((resolve) => {
				window.addEventListener('message', (event) => {
					if (event.data.source === EVENT_SOURCE && event.data.action === EVENT_ACTION.CART_DATA) {
						resolve(event.data.payload);
					}
				});

				window.top.postMessage({ source: EVENT_SOURCE, action: EVENT_ACTION.GET_CART }, '*');
			});
		} else if (isFunction(window.returnGoShopNow?.getCart)) {
			return window.returnGoShopNow.getCart();
		} else {
			return CartService.getCart();
		}
	}

	/**
	 * This method must be used to clear in-store cart data.
	 * Since stores are allowed to override the window.returnGoShopNow.deleteCart method, we need to attempt using it.
	 *
	 * @returns Promise<void>
	 */
	public static async clearCart(): Promise<void> {
		if (isInIFrame()) {
			return window.top.postMessage({ source: EVENT_SOURCE, action: EVENT_ACTION.CLEAR_CART }, '*');
		} else if (isFunction(window.returnGoShopNow?.deleteCart)) {
			return window.returnGoShopNow.deleteCart();
		} else {
			return CartService.clearCart();
		}
	}

	/**
	 * Default callback for buy now interceptor
	 * @param variantId
	 * @param quantity
	 * @param e
	 */
	public static async buyNowInterceptorCallback(variantId: string | number, quantity: number = 1, e?: Event): Promise<void> {
		InStoreService.blockEvent(e);

		await CartService.clearCart();
		await CartService.addItemToCart(variantId, quantity);

		InStoreService.navigateToPortal();
	}

	/**
	 * Returns a callback that intercepts clicks on the "Buy It Now" button.
	 */
	public static getBuyNowInterceptor() {
		return async (callback: (variantId: string, quantity?: number, e?: Event) => Promise<void> = InStoreService.buyNowInterceptorCallback) => {
			InStoreService.addEventListener('click', (e) => {
				const trigger = e.target as HTMLElement;
				const matches = trigger.closest(`${BUY_IT_NOW_SELECTOR}, [${ATTRIBUTE_ONCLICK}="${EVENT_BUY_NOW}"]`);

				if (matches) {
					let variantId: string | null = null;
					let quantity: number = 1;

					if (trigger.hasAttribute(ATTRIBUTE_VARIANT_ID)) {
						variantId = trigger.getAttribute(ATTRIBUTE_VARIANT_ID);
					} else {
						variantId = (document.querySelector('[name="id"]') as HTMLInputElement)?.value ?? null;
					}

					if (trigger.hasAttribute(ATTRIBUTE_QUANTITY)) {
						quantity = parseInt(trigger.getAttribute(ATTRIBUTE_QUANTITY));
					} else {
						quantity = parseInt((document.querySelector('input[name="quantity"]') as HTMLInputElement)?.value ?? '1');
					}

					callback(variantId, quantity, e);

					InStoreService.debug('Buy it now button clicked', { target: e.target, variantId, quantity });
				}
			});
		};
	}

	/**
	 * Returns a callback that intercepts all submissions of the Shopify Cart form,
	 * redirecting customers to the portal instead of the checkout page.
	 */
	public static getCheckoutInterceptor() {
		return (callback: (e: Event) => void = InStoreService.checkoutInterceptorCallback) => {
			InStoreService.addEventListener('submit', (e) => {
				const form = (e.target as HTMLFormElement).matches(`form[action*="/cart"], form[action*="/checkout"], [${ATTRIBUTE_ONSUBMIT}="${EVENT_CHECKOUT_REDIRECT}"]`);
				const submitter = (e as SubmitEvent).submitter as HTMLButtonElement | null;
				if (form && submitter?.name === 'checkout') {
					// Skip redirect to checkout interception if the form was submitted by a <button name="update">
					callback(e);

					InStoreService.debug('Redirect to checkout intercepted (form submission)', {
						target: e.target,
						submitter
					});
				}
			});

			InStoreService.addEventListener('click', (e) => {
				const matches = (e.target as HTMLElement).closest(`a[href*="/checkout"], [${ATTRIBUTE_ONCLICK}="${EVENT_CHECKOUT_REDIRECT}"]`);

				if (matches) {
					callback(e);

					InStoreService.debug('Redirect to checkout intercepted (click)', { target: e.target });
				}
			});

			InStoreService.addEventListener('click', (e) => {
				const clickedTarget = e.target as HTMLElement;
				const target = clickedTarget.closest('button, a, input') as HTMLElement;

                const targetedButtonMatches = CHECKOUT_KEYWORDS.some(keyword => target.innerText.toLowerCase().includes(keyword))
                const targetedInputMatches = target.matches('input') && CHECKOUT_KEYWORDS.some(keyword => (target as HTMLInputElement).value.toLowerCase().includes(keyword))

				if (target && (target.matches('[class*="checkout"], [id*="checkout"], [name*="checkout"]') || targetedButtonMatches || targetedInputMatches)) {
                    callback(e);

                    InStoreService.debug('Redirect to checkout intercepted (keyword click)', { target: e.target });
                }
			});

			if (typeof window.navigation !== 'undefined') {
				// Capture all navigation events to the '/checkout' page (supported only in Chrome, Edge, and Opera browsers)
				window.navigation.addEventListener('navigate', (e) => {
					const url = new URL(e.destination.url);

					if (url.pathname.includes('/cart/c')) {
						const cartId = url.pathname.split('/cart/c/')[1];
						setCookie('cart', cartId, 60 * 24 * 30);
					}

					if (url.pathname.includes('/checkout') || url.pathname.includes('/cart/c')) {
						callback(e);

						InStoreService.debug('Redirect to checkout intercepted (navigation)', { targetUrl: url });
					}
				});
			}
		};
	}

	/**
	 * Default callback for checkout interceptor
	 * @param e
	 */
	static checkoutInterceptorCallback(e?: Event) {
		InStoreService.blockEvent(e);
		InStoreService.navigateToPortal();
	}

	/**
	 * Returns a callback that intercepts all requests to the Shopify Cart API,
	 * ensuring the portal cart is updated in line with the changes in the Shopify Cart.
	 *
	 * Some Shopify themes use XMLHttpRequest or fetch to send requests to the Shopify Cart.js
	 * API for adding or modifying items in the cart.
	 * This interceptor captures all such requests and, upon detecting a cart update,
	 * it synchronizes the portal's cart and triggers a refresh.
	 */
	public static getFetchInterceptor() {
		return (callback: (e?: Event) => void) => {
			// Intercept click events
			InStoreService.addEventListener('click', (e) => {
				const target = e.target as HTMLElement;
				const matches = target.closest(`[${ATTRIBUTE_ONCLICK}="${EVENT_CART_UPDATE}"]`);

				if (matches) {
					const timeout = target.getAttribute(ATTRIBUTE_EVENT_TIMEOUT);

					setTimeout(() => callback(e), parseInt(timeout ?? '500'));

					InStoreService.debug('Cart update intercepted (click)', { target, timeout });
				}
			});

			// Intercept <form> submission requests
			InStoreService.addEventListener('submit', (e) => {
				const form = e.target as HTMLFormElement;

				const actionMatchesCartUrls = CART_URLS.some(url => form.action.endsWith(url));
				const actionMatchesAddToCartUrls = ADD_TO_CART_URLS.some(url => form.action.endsWith(url));

				const formHasOnSubmitEvent = form.matches(`[${ATTRIBUTE_ONSUBMIT}="${EVENT_CART_UPDATE}"]`);

				if (formHasOnSubmitEvent) {
					const timeout = form.getAttribute(ATTRIBUTE_EVENT_TIMEOUT);

					setTimeout(() => callback(e), parseInt(timeout ?? '500'));

					InStoreService.debug('Cart update intercepted (form submission)', { target: form, timeout });
				} else if (actionMatchesCartUrls) {
					const returnTo: HTMLInputElement = form.querySelector('[name="return_to"]');
					if (actionMatchesAddToCartUrls && returnTo && returnTo.value === '/checkout') {
						// If the form's return_to value is '/checkout', override it by adding the item using the Cart.js API.
						InStoreService.blockEvent(e);

						const quantity = (document.querySelector('input[name="quantity"]') as HTMLInputElement)?.value ?? '1';
						const variantId = document.querySelector(('[name="id"]') as any)?.value ?? null;

						CartService.addItemToCart(variantId, parseInt(quantity)).then(() => callback());

						InStoreService.debug('Cart update intercepted, redirect to checkout prevented (form submission)', { target: form });
					} else {
						callback(e);

						InStoreService.debug('Cart update intercepted (form submission)', { target: form });
					}
				}
			});

			// Intercept XHR requests
			const originalXhrOpen = XMLHttpRequest.prototype.open;
			const originalXhrSend = XMLHttpRequest.prototype.send;

			XMLHttpRequest.prototype.open = function (method, url, ...rest) {
				this._url = url;
				return originalXhrOpen.apply(this, [method, url, ...rest]);
			};

			XMLHttpRequest.prototype.send = function (body, ...args) {
				this.addEventListener('load', function (e) {
					if (
						InStoreService.isCartRestAPIRequest(this._url) ||
						InStoreService.isCartGraphQLAPIRequest(this._url, body?.toString())
					) {
						callback(e);

						InStoreService.debug('Cart update intercepted (XHR)', { url: this?._url });
					}
				});

				return originalXhrSend.apply(this, [body, ...args]);
			};

			// Intercept fetch requests
			const originalFetch = window.fetch;

			if (typeof originalFetch !== 'function') return;

			window.fetch = function (...args) {
				const [_, options] = args;

				const response = originalFetch.apply(this, args);

				response.then(res => {
					if (
						InStoreService.isCartRestAPIRequest(res.url) ||
						InStoreService.isCartGraphQLAPIRequest(res.url, options?.body?.toString())
					) {
						res.clone().json().then(() => {
							callback();

							InStoreService.debug('Cart update intercepted (fetch)', { url: res.url });
						});
					}
				});

				return response;
			};
		};
	}

	public static getOnBeforeUnloadInterceptor() {
		return (callback: (e?: Event) => void) => {
			window.addEventListener('beforeunload', callback);
		};
	}

	public static navigateToPortal(goBack: boolean = false) {
		const portalState = InStoreService.portalState;

		const url = new URL(portalState.settingsStore.settings.portalUrl);
		if (goBack) {
			url.searchParams.append('back', '1');
		}

		window.location.href = url.toString();
	}

	public static get isExchangeContext() {
		return (
			!!getCookie(LIST_OF_COOKIES.EXCHANGE_CONTEXT) &&
			!!getLocalStorageItem(LIST_OF_COOKIES.PORTAL_STATE)
		);
	}

	public static removeExchangeContext() {
		removeCookie(LIST_OF_COOKIES.EXCHANGE_CONTEXT);
		removeLocalStorageItem(LIST_OF_COOKIES.PORTAL_STATE);
	}

	public static get portalState(): SerializedData | null {
		const state = getLocalStorageItem(LIST_OF_COOKIES.PORTAL_STATE);

		if (state) {
			return JSON.parse(decodeURIComponent(atob(state)));
		}

		return null;
	}

	public static set portalState(state: SerializedData) {
		setLocalStorageItem(LIST_OF_COOKIES.PORTAL_STATE, btoa(encodeURIComponent(JSON.stringify(state))));
	}

	/**
	 * Checks if the request is directed to one of the Cart API endpoints.
	 * @param url - The URL to check.
	 * @private
	 */
	private static isCartRestAPIRequest(url: string) {
		let requestUrl: string;

		if (url.startsWith('http://') || url.startsWith('https://')) {
			requestUrl = new URL(url).pathname;
		} else {
			requestUrl = url;
		}

		return CART_URLS.some((cartUrl) => requestUrl.endsWith(cartUrl));
	}

	/**
	 * Checks if the request targets any Cart-related mutations in the GraphQL Storefront API.
	 * @param url
	 * @param body
	 * @private
	 */
	private static isCartGraphQLAPIRequest(url: string, body: string) {
		let requestUrl: string;

		if (url.startsWith('http://') || url.startsWith('https://')) {
			requestUrl = new URL(url).pathname;
		} else {
			requestUrl = url;
		}

		if (GRAPHQL_URL.test(requestUrl)) {
			let requestBody: any;
			try {
				requestBody = JSON.parse(body);
			} catch (error) {
				return false;
			}

			if (requestBody && requestBody.query && GRAPHQL_MUTATIONS.some((mutationName) => requestBody.query.includes(mutationName))) {
				return true;
			}
		}

		return false;
	}

	public static debug(message: string, ...objects: any) {
		if (window?.returnGoShopNow?.debug === true) {
			console.log(`%c[ReturnGO] %c${message}`, 'color: lightskyblue; font-weight: bold;', 'color: mediumspringgreen; font-weight: bold;', ...objects);
		}
	}

	/**
	 * Attaches an event listener to each root element.
	 * @param type - The type of event to listen for.
	 * @param listener - The function to be called when the event is triggered.
	 * @private
	 */
	private static addEventListener(type: string, listener: EventListener) {
		[document, document.documentElement].forEach((element) => element.addEventListener(type, listener, { capture: true }));
	}

	/**
	 * Blocks the event and stops its propagation.
	 * @param e - The event to be blocked.
	 */
	public static blockEvent(e?: Event) {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
			e.stopImmediatePropagation();
		}
	}
};
