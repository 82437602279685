import { OrderItemStatus } from '../stores/ReturnRequestStore/types';
import { RMA_STATUS } from './types';
import { PortalType } from '../stores/PortalStore/types';
import InStoreService from '../services/InStoreService/InStoreService';
import { CartData } from '../services/InStoreService/InStoreService.types';

export const getReturnGoObject = () => {
  return window.ReturnGO ?? window.Shopify
}

export const getShop = () => (
  getReturnGoObject()?.shop
);

export const getReturnGoShopNow = () => {
  return window.returnGoShopNow;
}

export const getCustomerId = () => (
  (window as any)?.ShopifyAnalytics?.meta?.page?.customerId
  ?? (window as any)?.meta?.page?.customerId
  ?? (window as any)?.__st?.cid
);

export const getLocale = () => {
  return getReturnGoObject()?.locale ?? navigator.language.slice(0, 2).toLowerCase();
}

export const isReturnPortal = () => {
    return getShop() && (/.*(\?returns|return-assistant=1).*/.test(window.location.href) || isEmbedded())
}

export const isEmbedded = (id = 'ReturngoPortalEmbedding') => (
  !!getEmbeddedContainer(id)
)

export const getEmbeddedContainer = (id = 'ReturngoPortalEmbedding') => (
  document.getElementById(id)
)

export const shouldShowLoader = (id = 'ReturngoPortalEmbedding') => {
  const container = getEmbeddedContainer(id);
  return !!container && container.dataset?.showLoader !== "0";
}

export const getRMAStatusText = (status: RMA_STATUS) => (
  status === RMA_STATUS.PENDING_APPROVAL
    ? 'RMA_PENDING_APPROVAL'
    : status === RMA_STATUS.APPROVED
      ? 'RMA_APPROVED'
      : status === RMA_STATUS.REJECTED
        ? 'RMA_REJECTED'
        : 'RMA_DONE'
);

export const getRMAStatusClassName = (status: RMA_STATUS) => (
  status === RMA_STATUS.PENDING_APPROVAL
    ? 'pendingApproval'
    : status === RMA_STATUS.APPROVED
      ? 'accepted'
      : status === RMA_STATUS.REJECTED
        ? 'rejected'
        : 'done'
);

export const getOrderItemStatus = (status: OrderItemStatus) => {
  const statuses = {
    'not-eligible': 'BADGE_NOT_ELIGIBLE',
    'unfulfilled': 'BADGE_UNFULFILLED',
    'expired': 'BADGE_EXPIRED',
    'returned': 'BADGE_RETURNED',
    'partially-eligible': 'BADGE_PARTIALLY_ELIGIBLE',
    'out_of_stock': 'OUT_OF_STOCK',
    'different_price': 'DIFFERENT_PRICE'
  }
  return statuses[status];
}
export enum SOLUTION_TYPES {
  SHIP_LATER,
  KEEP,
  DONATE,
  RETURN_FOR_REFUND,
  RETURN_FOR_CREDIT,
  VARIANT_EXCHANGE,
  RETURN_FOR_BANK,
  CANCEL_ORDER,
  MANUAL_EXCHANGE,
  RETURN_FOR_GIFT_CARD,
  PRODUCT_EXCHANGE,
  INSTANT_REFUND,
  NO_OFFERS
}

export const dayOfWeekAsString = (dayIndex) => {
  const days = {
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat',
    7: 'Sun',
  };
  return days[dayIndex] || '';
}

export const getHoursInUSFormat = (date) => {
  if (!date) {
    return '';
  }
  const time = new Date(date);
  return time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric' });
}

export const getMixedSolutions = () => ({
  //Define for each solution type, which other solutions types it can go together with
  [SOLUTION_TYPES.SHIP_LATER]: [SOLUTION_TYPES.SHIP_LATER, SOLUTION_TYPES.KEEP, SOLUTION_TYPES.DONATE, SOLUTION_TYPES.VARIANT_EXCHANGE, SOLUTION_TYPES.MANUAL_EXCHANGE, SOLUTION_TYPES.PRODUCT_EXCHANGE, SOLUTION_TYPES.NO_OFFERS],
  [SOLUTION_TYPES.KEEP]: [SOLUTION_TYPES.SHIP_LATER, SOLUTION_TYPES.KEEP, SOLUTION_TYPES.DONATE, SOLUTION_TYPES.VARIANT_EXCHANGE, SOLUTION_TYPES.MANUAL_EXCHANGE, SOLUTION_TYPES.PRODUCT_EXCHANGE, SOLUTION_TYPES.NO_OFFERS],
  [SOLUTION_TYPES.DONATE]: [SOLUTION_TYPES.SHIP_LATER, SOLUTION_TYPES.KEEP, SOLUTION_TYPES.DONATE, SOLUTION_TYPES.VARIANT_EXCHANGE, SOLUTION_TYPES.MANUAL_EXCHANGE, SOLUTION_TYPES.PRODUCT_EXCHANGE, SOLUTION_TYPES.NO_OFFERS],
  [SOLUTION_TYPES.RETURN_FOR_REFUND]: [SOLUTION_TYPES.RETURN_FOR_REFUND, SOLUTION_TYPES.VARIANT_EXCHANGE, SOLUTION_TYPES.MANUAL_EXCHANGE, SOLUTION_TYPES.PRODUCT_EXCHANGE, SOLUTION_TYPES.NO_OFFERS],
  [SOLUTION_TYPES.RETURN_FOR_CREDIT]: [SOLUTION_TYPES.RETURN_FOR_CREDIT, SOLUTION_TYPES.RETURN_FOR_GIFT_CARD, SOLUTION_TYPES.VARIANT_EXCHANGE, SOLUTION_TYPES.MANUAL_EXCHANGE, SOLUTION_TYPES.PRODUCT_EXCHANGE, SOLUTION_TYPES.NO_OFFERS],
  [SOLUTION_TYPES.RETURN_FOR_GIFT_CARD]: [SOLUTION_TYPES.RETURN_FOR_CREDIT, SOLUTION_TYPES.RETURN_FOR_GIFT_CARD, SOLUTION_TYPES.VARIANT_EXCHANGE, SOLUTION_TYPES.MANUAL_EXCHANGE, SOLUTION_TYPES.PRODUCT_EXCHANGE, SOLUTION_TYPES.NO_OFFERS],
  [SOLUTION_TYPES.VARIANT_EXCHANGE]: [SOLUTION_TYPES.SHIP_LATER, SOLUTION_TYPES.KEEP, SOLUTION_TYPES.DONATE,
  SOLUTION_TYPES.RETURN_FOR_REFUND, SOLUTION_TYPES.RETURN_FOR_CREDIT, SOLUTION_TYPES.RETURN_FOR_GIFT_CARD, SOLUTION_TYPES.VARIANT_EXCHANGE,
  SOLUTION_TYPES.RETURN_FOR_BANK, SOLUTION_TYPES.MANUAL_EXCHANGE, SOLUTION_TYPES.PRODUCT_EXCHANGE, SOLUTION_TYPES.INSTANT_REFUND, SOLUTION_TYPES.NO_OFFERS],
  [SOLUTION_TYPES.RETURN_FOR_BANK]: [SOLUTION_TYPES.RETURN_FOR_BANK, SOLUTION_TYPES.VARIANT_EXCHANGE, , SOLUTION_TYPES.MANUAL_EXCHANGE, SOLUTION_TYPES.NO_OFFERS],
  [SOLUTION_TYPES.CANCEL_ORDER]: [SOLUTION_TYPES.RETURN_FOR_BANK, SOLUTION_TYPES.VARIANT_EXCHANGE, , SOLUTION_TYPES.MANUAL_EXCHANGE, SOLUTION_TYPES.NO_OFFERS],
  [SOLUTION_TYPES.MANUAL_EXCHANGE]: [SOLUTION_TYPES.SHIP_LATER, SOLUTION_TYPES.KEEP, SOLUTION_TYPES.DONATE,
  SOLUTION_TYPES.RETURN_FOR_REFUND, SOLUTION_TYPES.RETURN_FOR_CREDIT, SOLUTION_TYPES.RETURN_FOR_GIFT_CARD, SOLUTION_TYPES.VARIANT_EXCHANGE,
  SOLUTION_TYPES.RETURN_FOR_BANK, SOLUTION_TYPES.MANUAL_EXCHANGE, SOLUTION_TYPES.PRODUCT_EXCHANGE, SOLUTION_TYPES.INSTANT_REFUND, SOLUTION_TYPES.NO_OFFERS],
  [SOLUTION_TYPES.PRODUCT_EXCHANGE]: [SOLUTION_TYPES.SHIP_LATER, SOLUTION_TYPES.KEEP, SOLUTION_TYPES.DONATE,
  SOLUTION_TYPES.RETURN_FOR_REFUND, SOLUTION_TYPES.RETURN_FOR_CREDIT, SOLUTION_TYPES.RETURN_FOR_GIFT_CARD, SOLUTION_TYPES.VARIANT_EXCHANGE,
  SOLUTION_TYPES.RETURN_FOR_BANK, SOLUTION_TYPES.MANUAL_EXCHANGE, SOLUTION_TYPES.PRODUCT_EXCHANGE, SOLUTION_TYPES.INSTANT_REFUND, SOLUTION_TYPES.NO_OFFERS],
  [SOLUTION_TYPES.INSTANT_REFUND]: [SOLUTION_TYPES.INSTANT_REFUND, SOLUTION_TYPES.VARIANT_EXCHANGE, SOLUTION_TYPES.MANUAL_EXCHANGE, SOLUTION_TYPES.PRODUCT_EXCHANGE, SOLUTION_TYPES.NO_OFFERS],
  [SOLUTION_TYPES.NO_OFFERS]: [SOLUTION_TYPES.SHIP_LATER, SOLUTION_TYPES.KEEP, SOLUTION_TYPES.DONATE, SOLUTION_TYPES.RETURN_FOR_REFUND, SOLUTION_TYPES.RETURN_FOR_CREDIT,
  SOLUTION_TYPES.VARIANT_EXCHANGE, SOLUTION_TYPES.RETURN_FOR_BANK, SOLUTION_TYPES.CANCEL_ORDER, SOLUTION_TYPES.MANUAL_EXCHANGE, SOLUTION_TYPES.RETURN_FOR_GIFT_CARD,
  SOLUTION_TYPES.PRODUCT_EXCHANGE, SOLUTION_TYPES.INSTANT_REFUND, SOLUTION_TYPES.NO_OFFERS],
});

export const isReturnGOSolutionType = (solutionType): boolean => (
  [SOLUTION_TYPES.SHIP_LATER,
  SOLUTION_TYPES.KEEP,
  SOLUTION_TYPES.DONATE].includes(solutionType)
)

export const isStandardSolutionType = (solutionType): boolean => (
  !isReturnGOSolutionType(solutionType) && solutionType != SOLUTION_TYPES.VARIANT_EXCHANGE && solutionType != SOLUTION_TYPES.MANUAL_EXCHANGE && solutionType != SOLUTION_TYPES.NO_OFFERS
)

export const isCreditResolution = (solutionType): boolean => (
  solutionType != SOLUTION_TYPES.RETURN_FOR_REFUND && solutionType != SOLUTION_TYPES.VARIANT_EXCHANGE && solutionType != SOLUTION_TYPES.RETURN_FOR_BANK && solutionType != SOLUTION_TYPES.MANUAL_EXCHANGE
)

/*
Check if a given option represents the size option.
TODO: consider translations
 */

export const isSizeOption = (optionName: string, sizeOptionName: string): boolean => (
  optionName.includes(sizeOptionName) || optionName.toLowerCase() === 'size'
)

export const getCookie = (name) => {
  let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
}

export const setCookie = (name: string, value: string, minutes: number) => {
  let d = new Date();
  d.setTime(d.getTime() + minutes * 60 * 1000); // Convert minutes to milliseconds
  document.cookie = name + '=' + value + ';path=/;expires=' + d.toUTCString();
}

export const removeCookie = (name: string) => {
  setCookie(name, '', -1);
}

export const getURLSearchParam = (name: string) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(name);
}

export const getDiscountCodeCookieName = () => (
  `returngo_discount_code_${getCustomerId()}`
)

export const getDiscountNoCodeValue = () => (
  'RETURNGO_NO_CODE'
)

export const getCustomerIdCookieName = () => (
  'returngo_customer_id'
)

/** sprintf is a lib that allowes replacements and formating of placeholdres in string with argumanets (%s - for simple string replacement) */
/** all placeholders start with %, this method escapes all % signs that are NOT %s sprintf placeholder. */
export const normalizeForSprintf = (str) => {
  const regExp = /%s/;
  let res = '';
  [...str].forEach((char, index, str) => {
    const nextChar = str[index + 1];
    if (char === '%' && (!nextChar || (nextChar && !`${char}${nextChar}`.match(regExp)))) {
      res += '%';
    }
    res += char;
  })
  return res;
}

export const getLang = () => {
  return navigator.languages?.[0] ?? navigator.language;
}

export const isAtPage = (name) => {
  const regex = new RegExp('^(?:/[a-zA-Z]{2})?/' + name + '/?$');
  return regex.test(window.location.pathname);
}

export const isValidEmail = (email) => {
  const mailformat = /^\w+([\+\.-]?\w+\+?)*@\w+([\.-]?\w+)*(\.\w+)+$/;
  return email.match(mailformat);
}

export const getDisplayCurrency = (storeCurrencyCode: string) => {
  return `${getReturnGoObject()?.currency?.active || storeCurrencyCode}`;
}

/*
this method allows to truncate the line (lines) in the middle ant put 3 ellipsis
Note: need to know number of symbols allowed
*/
export const fitTextTwoLines = (text: string, symbols: number = 40) => {
  if (text.length <= symbols) {
    return text;
  }

  const symbolsAlowed = symbols - 3; // get number of symbols that fit N lines and deduct 3 dots
  const stringBegining = text.substring(0, Math.floor(symbolsAlowed / 2));
  const stringEnding = text.substring(text.length - Math.floor(symbolsAlowed / 2));

  return `${stringBegining}...${stringEnding}`;
};

// this method transforms bytes in readable format
export const formatBytes = (bytes: number, decimals: number = 2): string => {
  if (!+bytes) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const isNotNullOrUndefined = (value): boolean => value !== null && value !== undefined;

export const isTrackingMockModeEnabledFromUrl = () => (
  window.location.href.includes('trackingMock=1')
);

export const getPortalType = () => {
  return (window?.returnGoIntegrationData?.portal_mode as IPortalMode)?.tracking ? PortalType.TRACKING : PortalType.RETURN;
}

export const isInStoreExchangesModeEnabledFromUrl = () => (
  window.location.href.includes('exchangeInStore=1')
);

export const isTestModeEnabledFromUrl = () => (
  window.location.href.includes('testMode=1')
);

export const isShopNowPocModeEnabledFromUrl = () => (
  window.location.href.includes('shopNowPocMode=1')
);

export const setLocalStorageItem = (key: string, value: any) => {
  localStorage.setItem(key, value);
};

export const getLocalStorageItem = (key: string): string | null => {
  return localStorage.getItem(key);
}

export const removeLocalStorageItem = (key: string) => {
  localStorage.removeItem(key);
}

export function normalizeShopifyGid(gid: string | number, name: string): string {
  return `${gid}`.indexOf(`gid://shopify/${name}/`) === -1
      ? `gid://shopify/${name}/${gid}`
      : `${gid}`;
}

export function denormalizeShopifyGid(gid: string | number, name: string): string {
  return `${gid}`.replace(`gid://shopify/${name}/`, '');
}

export function safeDivision(dividend: number, divider: number, fallback: number, epsilon: number = 0.00000): number {
  return divider < epsilon ? fallback : dividend / divider;
}

export function isFunction(value: any): boolean {
  return typeof value === 'function';
}

export function isInIFrame(): boolean {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function setReturnGoShopNow(
    handleCartUpdate?: (...params: any[]) => void,
    handleCheckout?: (e?: Event) => void,
    handleBuyNow?: (variantId: string | number, quantity?: number, e?: Event) => Promise<void>,
    getCart?: () => Promise<CartData>,
    deleteCart?: () => Promise<void>
) {
  function test() {
    const buyNowInterceptor = InStoreService.getBuyNowInterceptor();
    const checkoutInterceptor = InStoreService.getCheckoutInterceptor();
    const fetchInterceptor = InStoreService.getFetchInterceptor();

    window.returnGoShopNow.debug = true;

    buyNowInterceptor(async (variantId, quantity, e) => {
      if (e) {
        InStoreService.blockEvent(e);
      }
    })

    checkoutInterceptor((e) => {
      if (e) {
        InStoreService.blockEvent(e);
      }
    })

    fetchInterceptor((e) => {
      if (e) {
        InStoreService.blockEvent(e);
      }
    })
  }

  window.returnGoShopNow = {
    debug: false,
    getCart,
    deleteCart,
    ...window.returnGoShopNow,
  };

  if (typeof window.returnGoShopNow.test === 'undefined') {
    // window.returnGoShopNow.test should not be allowed to be overwritten
    Object.defineProperty(window.returnGoShopNow, 'test', {
      value: test,
      writable: false,
      configurable: false
    });
  }

  if (typeof window.returnGoShopNow.handleCartUpdate === 'undefined') {
    // window.returnGoShopNow.handleCartUpdate should not be allowed to be overwritten
    Object.defineProperty(window.returnGoShopNow, 'handleCartUpdate', {
      value: handleCartUpdate,
      writable: false,
      configurable: false
    });
  }

  if (typeof window.returnGoShopNow.handleCheckout === 'undefined') {
    // window.returnGoShopNow.handleCheckout should not be allowed to be overwritten
    Object.defineProperty(window.returnGoShopNow, 'handleCheckout', {
      value: handleCheckout,
      writable: false,
      configurable: false
    });
  }

  if (typeof window.returnGoShopNow.handleBuyNow === 'undefined') {
    // window.returnGoShopNow.handleBuyNow should not be allowed to be overwritten
    Object.defineProperty(window.returnGoShopNow, 'handleBuyNow', {
      value: handleBuyNow,
      writable: false,
      configurable: false
    });
  }
}
