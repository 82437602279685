export interface FetchResponse {
  data: any;
  error?: string;
  errors?: Record<string, string>;
}

export enum Mode {
  API = 'api',
  MOCK = 'mock',
}
