const data = {
    'case_id': 15,
    'order_products': [
      {
        'product_case_id': '14',
        'product_id': '1',
        'offers': [
          {
            "solution_type": "4",
            "disabled": false,
            "credits": 64.3438,
            "reward": 49.4838,
            "resolution_name": "CUSTOM_RESOLUTION_NAME_2",
            "resolution_description": "CUSTOM_RESOLUTION_DESCRIPTION_2",
            "return_method": "RETURNMETHOD_DISPLAYNAME242",
            "return_method_description": "RETURNMETHOD_DESC242",
            "return_method_id": 242,
            "return_method_type": 3,
            "is_customer_address_required": false,
            "has_shipment": true,
            "fee_data": [
                {
                    "amount": 1.4414850000000001,
                    "fee_type": {
                        "value": "FEE_TYPE_RESTOCKING_FEE",
                        "label": "Restocking fee"
                    }
                }
            ],
            "instant_refund_provider": null,
            "refund_data": {
                "credits": 64.3438,
                "refund": 0,
                "gift_card": 0,
                "instant_refund": 0
            },
            "return_label_fee_payment": "DEDUCT_FROM_REFUNDS",
            "drop_off_locations": [
                {
                    "id": "wmcard",
                    "name": "Cost Plus World Market - Redding",
                    "address_id": 2305,
                    "hours_may_vary": true,
                    "lat": 40.5914093,
                    "lng": -122.3595566,
                    "distance": 39.2066,
                    "time_zone": "America\/Los_Angeles",
                    "display": [
                        {
                            "label": "Promotions",
                            "value": "10% off your qualifying Cost Plus World Market purchase."
                        },
                        {
                            "label": "Notes",
                            "value": "Masks are required at this store"
                        }
                    ],
                    "is_visible": true,
                    "description": "Returns are accepted at the \"Buy online pick up in store\" register.",
                    "opening_hours": {
                        "2022-11-25T10:00:00-08:00": {
                            "day_of_week": [
                                1,
                                2,
                                3,
                                4,
                                5,
                                6
                            ],
                            "opening_time": "2022-11-25T10:00:00-08:00",
                            "closing_time": "2022-11-25T19:00:00-08:00"
                        },
                        "2022-11-25T12:00:00-08:00": {
                            "day_of_week": [
                                7
                            ],
                            "opening_time": "2022-11-25T12:00:00-08:00",
                            "closing_time": "2022-11-25T17:00:00-08:00"
                        }
                    },
                    "address": {
                        "name": "Cost Plus World Market - Redding",
                        "street1": "1080 Hilltop Drive",
                        "street2": "",
                        "city": "Redding",
                        "state": "CA",
                        "zip": "96003",
                        "country": null,
                        "phone": "n\/a",
                        "email": "n\/a"
                    }
                },
                {
                    "id": "HB1459",
                    "name": "Hibbett Sports",
                    "address_id": 27642,
                    "hours_may_vary": true,
                    "lat": 40.5882757,
                    "lng": -122.355413,
                    "distance": 39.5073,
                    "time_zone": "America\/Los_Angeles",
                    "display": [
                        {
                            "label": "Promotions",
                            "value": "10% off your qualifying Hibbett purchase."
                        }
                    ],
                    "is_visible": true,
                    "description": null,
                    "opening_hours": [],
                    "address": {
                        "name": "Hibbett Sporting Goods 1459",
                        "street1": "900 DANA DR",
                        "street2": "",
                        "city": "REDDING",
                        "state": "CA",
                        "zip": "96003",
                        "country": null,
                        "phone": "n\/a",
                        "email": "n\/a"
                    }
                },
                {
                    "id": "ub1238",
                    "name": "Ulta Beauty",
                    "address_id": 17947,
                    "hours_may_vary": true,
                    "lat": 40.5882757,
                    "lng": -122.355413,
                    "distance": 39.5073,
                    "time_zone": "America\/Los_Angeles",
                    "display": [
                        {
                            "label": "Promotions",
                            "value": "$5.00 off your qualifying Ulta Beauty purchase of $15 or more"
                        }
                    ],
                    "is_visible": true,
                    "description": "Go to the cashwrap to return your items.",
                    "opening_hours": {
                        "2022-11-25T11:00:00-08:00": {
                            "day_of_week": [
                                1,
                                2,
                                3,
                                4
                            ],
                            "opening_time": "2022-11-25T11:00:00-08:00",
                            "closing_time": "2022-11-25T19:00:00-08:00"
                        }
                    },
                    "address": {
                        "name": "Ulta 1238",
                        "street1": "900 Dana Drive",
                        "street2": "",
                        "city": "Redding",
                        "state": "CA",
                        "zip": "96003",
                        "country": "US",
                        "phone": "n\/a",
                        "email": "n\/a"
                    }
                },
                {
                    "id": "fxo5496",
                    "name": "FedEx Office",
                    "address_id": 6031,
                    "hours_may_vary": true,
                    "lat": 40.577271,
                    "lng": -122.3526375,
                    "distance": 40.222,
                    "time_zone": "America\/Los_Angeles",
                    "display": [],
                    "is_visible": true,
                    "description": "Go to the shipping counter and show your QR code.",
                    "opening_hours": {
                        "2022-11-25T08:00:00-08:00": {
                            "day_of_week": [
                                1,
                                2,
                                3,
                                4,
                                5
                            ],
                            "opening_time": "2022-11-25T08:00:00-08:00",
                            "closing_time": "2022-11-25T19:00:00-08:00"
                        },
                        "2022-11-25T09:00:00-08:00": {
                            "day_of_week": [
                                6
                            ],
                            "opening_time": "2022-11-25T09:00:00-08:00",
                            "closing_time": "2022-11-25T18:00:00-08:00"
                        },
                        "2022-11-25T12:00:00-08:00": {
                            "day_of_week": [
                                7
                            ],
                            "opening_time": "2022-11-25T12:00:00-08:00",
                            "closing_time": "2022-11-25T18:00:00-08:00"
                        }
                    },
                    "address": {
                        "name": "FedEx Office 5496",
                        "street1": "2105 Churn Creek Rd",
                        "street2": "",
                        "city": "Redding",
                        "state": "CA",
                        "zip": "96002",
                        "country": "US",
                        "phone": "n\/a",
                        "email": "n\/a"
                    }
                }
            ],
            "agregator_code": "happy-returns",
            "product_case_offer_id": 3232
          },
          {
              'solution_type': 6,
              'product_case_offer_id': 0,
              'credits': 25.99,
              'resolution_name': 'RESOLUTION_NAME7',
              'resolution_description': 'RESOLUTION_DESC7',
              'return_method_id': 0,
              'return_method': 'RETURNMETHOD_NAME1',
              'return_method_description': 'RETURNMETHOD_DESC1',
              'is_customer_address_required': false,
              'has_shipment': false,
              'fee': 0,
          },
          {
            'solution_type': 4,
            'product_case_offer_id': 1,
            'credits': 25.99,
            'resolution_name': 'RESOLUTION_NAME6',
            'resolution_description': 'RESOLUTION_DESC6',
            'return_method_id': 2,
            'return_method': 'RETURNMETHOD_NAME1',
            'is_customer_address_required': true,
            'return_method_description': 'RETURNMETHOD_DESC1',
            'has_shipment': true,
            'fee': 0,
          },
          {
            "solution_type": 3,
            "disabled": true,
            "credits": 100,
            "reward": 0,
            "resolution_name": "RESOLUTION_NAME4",
            "resolution_description": "RESOLUTION_DESC4",
            "return_method": "RETURNMETHOD_DISPLAYNAME2",
            "return_method_description": "RETURNMETHOD_DESC2",
            "return_method_id": 246,
            "return_method_type": 2,
            "is_customer_address_required": false,
            "has_shipment": true,
            "fee": null,
            "fee_data": [
                {
                    "amount": "5.00",
                    "fee_type": {
                        "value": "FEE_TYPE_RESTOCKING_FEE",
                        "label": "Restocking fee"
                    }
                },
                {
                    "amount": "1.00",
                    "fee_type": {
                        "value": "FEE_TYPE_SHIPMENT_FEE",
                        "label": "Per shipment fee"
                    }
                }
            ],
            "refund_data": {
                "credits": 0,
                "refund": 100,
                "gift_card": 0
            },
            "product_case_offer_id": 9341
          }
        ]
      },
      {
        'product_case_id': '15',
        'product_id': '2',
        'offers': [
          {
            "drop_off_locator_url": "https://done.co.il",
            "solution_type": "4",
            "disabled": false,
            "credits": 64.3438,
            "reward": 49.4838,
            "resolution_name": "CUSTOM_RESOLUTION_NAME_2",
            "resolution_description": "CUSTOM_RESOLUTION_DESCRIPTION_2",
            "return_method": "RETURNMETHOD_DISPLAYNAME242",
            "return_method_description": "RETURNMETHOD_DESC242",
            "return_method_id": 242,
            "return_method_type": 3,
            "is_customer_address_required": false,
            "has_shipment": true,
            "fee_data": [
                {
                    "amount": 1.4414850000000001,
                    "fee_type": {
                        "value": "FEE_TYPE_RESTOCKING_FEE",
                        "label": "Restocking fee"
                    }
                }
            ],
            "instant_refund_provider": null,
            "refund_data": {
                "credits": 64.3438,
                "refund": 0,
                "gift_card": 0,
                "instant_refund": 0
            },
            "return_label_fee_payment": "DEDUCT_FROM_REFUNDS",
            /** empty dropoff locations - should filter this out */
            "drop_off_locations": [],
            "agregator_code": "happy-returns",
            "product_case_offer_id": 3201
          },
          {
            "drop_off_locator_url": "https://done.co.il",
            'solution_type': 0,
            'product_case_offer_id': 0,
            'credits': 45.99,
            'resolution_name': 'RESOLUTION_NAME1',
            'resolution_description': 'RESOLUTION_DESC1',
            'return_method_id': 2,
            'return_method': 'RETURNMETHOD_NAME1',
            'is_customer_address_required': true,
            'return_method_description': 'RETURNMETHOD_DESC1',
            'has_shipment': false,
            'fee': 3,
          },
          {
            "drop_off_locator_url": "https://done.co.il",
            'solution_type': 1,
            'product_case_offer_id': 1,
            'credits': 55.99,
            'resolution_name': 'RESOLUTION_NAME2',
            'resolution_description': 'RESOLUTION_DESC2',
            'return_method_id': 2,
            'return_method': 'RETURNMETHOD_NAME1',
            'is_customer_address_required': true,
            'return_method_description': 'RETURNMETHOD_DESC1',
            'has_shipment': false,
            'fee': null,
          }
        ]
      },
      {
        'product_case_id': '16',
        'product_id': '3',
        'offers': []
      },
      {
        'product_case_id': '17',
        'product_id': '4',
        'offers': []
      },
      {
        'product_case_id': '18',
        'product_id': '5',
        'offers': [
          {
            'solution_type': 8,
            'product_case_offer_id': 0,
            'credits': 25.99,
            'resolution_name': 'RESOLUTION_NAME8',
            'resolution_description': 'RESOLUTION_DESC8',
            'return_method_id': 0,
            'return_method': 'RETURNMETHOD_NAME1',
            'is_customer_address_required': true,
            'return_method_description': 'RETURNMETHOD_DESC1',
            'has_shipment': true,
            'fee': 0,
          }
        ]
      },
    ],
    'shipping_address': {
      'address': '63139 Newport Drive',
      'apartment': '',
      'city': 'Montrose',
      'country': 'US',
      'region': 'CO',
      'zip': '81403',
    }
  }
;

export const fetch = (init?: RequestInit) => {
  return { data };
}
