const translations = {
  strings: {
    en: {
      cart: {
        'RETURN_CREDITS': 'Return Credits',
        'CANNOT_USE_CREDITS_IN_THIS_PURCHASE': 'Cannot use return credits in this purchase',
        'CART_SUBTOTAL': 'Cart Subtotal',
        'TOTAL': 'Total',
      },
      account: {
        'RETURN_CREDITS': 'Return Credits',
        'YOU_HAVE_N_RETURN_CREDITS': 'You have |%s| return credits.',
        'VIEW_TRANSACTIONS': 'View transactions',
      },
      assistant: {
        'ADDRESS': 'ADDRESS',
        'ALL_INFORMATION_I_PROVIDED_CORRECT': 'All information I provided is correct. I accept consistent use of this service and solution will be examined.',
        'ADD_COMMENTS': 'Add comments (optional)',
        'APARTMENT_SUITE_ETC': 'APARTMENT, SUITE, ETC',
        'ARE_YOU_STILL_ABLE_TO_USE_ITEM': 'Are you still able to use the item?',
        'ARE_YOU_SURE': 'Are you sure?',
        'ARE_YOU_SURE_CANCEL': 'Are you sure you would like to cancel the order?',
        'BACK': 'back',
        'BAD_INSTRUCTIONS': 'Bad instructions / difficult to use',
        'BADGE_GET': 'Get',
        'BADGE_EXPIRED': 'Expired',
        'BADGE_NOT_ELIGIBLE': 'Not eligible',
        'BADGE_PARTIALLY_ELIGIBLE': 'Partially eligible',
        'BADGE_RETURNED': 'Returned',
        'BADGE_UNFULFILLED': 'Unfulfilled',
        'BROWN': 'Brown',
        'VIEW_ALL_OPTIONS': 'View all options',
        'CANCEL_ORDER': 'Cancel order',
        'CANNOT_CANCEL_ORDER': 'This order can\'t be canceled. If you have any questions, please contact our support.',
        'CHOOSE': 'Choose',
        'CHOOSE_RETURN_SOLUTION': 'Choose return solution',
        'CHOOSE_RETURN_EXCHANGE_METHOD': 'Choose a return method for the exchange',
        'CHOOSE_YOUR_RETURN_ALTERNATIVE': 'How we can resolve it?',
        'CHOOSE_ANY_ITEMS': 'Choose any items that apply',
        'CITY': 'CITY',
        'COLOR_IS_NOT_AS_EXPECTED': 'Color is not as expected',
        'CONFIRM': 'confirm',
        'CONFIRM_CANCEL': 'Confirm cancel',
        'CONFIRM_OR_ADJUST_SHIPPING_ADDRESS': 'Confirm or adjust our shipping address',
        'CONFIRM_STEP': 'Confirm',
        'CONFIRM_THE_FOLLOWING': 'Please confirm the following',
        'CONTACT_DETAILS': 'Contact Details',
        'COUNTRY': 'COUNTRY',
        'CREDIT_ME': 'Credit me',
        'CRUNCHING_THE_NUMBER': 'Crunching the numbers',
        'DAYS': 'DAYS',
        'DONATE_THE_ITEM': 'Donate the item',
        'DESCRIBE': 'Describe',
        'EDIT': 'EDIT',
        'ENTER_BANK_DETAILS': 'Enter your bank details for the refund',
        'EMAIL_FIELD': 'EMAIL',
        'EMAIL_ADDRESS': 'Email Address',
        'EXCHANGE': 'Exchange',
        'EXCHANGE_INFO': 'Exchange info',
        'EZP_FIELD': 'EMAIL/ZIP/PHONE',
        'FAQ': 'FAQ',
        'FEEL_FREE_TO_PLAY': 'Feel free to play with no side-effects',
        'FIND_MY_ORDER': 'find my order',
        'FIRST_NAME': 'First Name',
        'FOR_ANY_QUESTIONS_PLEASE_CONTACT': 'For any questions or inquiries, please contact |customer support|.',
        'FREE': 'FREE',
        'FREQUENTLY_ASKED_QUESTIONS': 'Frequently asked questions',
        'FUNCTIONALITY_IS_NOT_AS_EXPECTED': 'Functionality is not as expected',
        'GATHERING_IMPORTANT_DATA': 'Gathering important data',
        'GENERATING_BEST_OFFER': 'Generating the best offer for you',
        'GIFT_CARD': 'Gift card',
        'GIFT_RECIPIENTS_INFORMATION': 'Gift recipient\'s information',
        'GIFT_RECIPIENTS_INFORMATION_CONTENT': 'Fill in your contact details so we can update you regarding',
        'GIFT_ORDER_NUMBER_FIELD': 'ORDER NUMBER on INVOICE',
        'GIFT_EZP_FIELD': 'Gifter\'s EMAIL/ZIP/PHONE',
        'GIFT_RECIPIENTS_FULL_NAME_FIELD': 'FULL NAME',
        'GIFT_RECIPIENTS_EMAIL_FIELD': 'EMAIL ADDRESS',
        'GIFT_RECIPIENTS_ADDRESS_INVALID_FORMAT': 'Invalid gift recipient email',
        'GO_BACK': 'Go Back',
        'GOT_IT': 'Got it',
        'GREAT': 'GREAT!',
        'GREAT_CHOICE': 'Great choice!',
        'IBAN_NUMBER_FIELD': 'IBAN NUMBER',
        'INSTANT': 'Instant',
        'INSTANT_STORE_CREDIT': 'Instant store-credit',
        'IN_STORE_CREDITS': 'In store-credits',
        'I_AGREE_CREDIT_ME': 'I agree! Credit me',
        'I_CAN_NOT_USE_IT': 'I can\'t use it',
        'I_CAN_STILL_USE_IT': 'I can still use it',
        'I_DECLINE_THE_OFFER': 'I decline the offer',
        'IS_THE_ITEM_UNUSABLE_FOR_YOU_DUE_TO_THIS_DELAY': 'Is the item unusable for you due to this delay?',
        'ITEMS': 'Items',
        'ITEMS_INFO': 'Item Info',
        'KEEP_THE_ITEM': 'Keep the item',
        'LARGE': 'Large',
        'LAST_NAME': 'Last Name',
        'LEARN_MORE': 'LEARN MORE',
        'LETS_TRACK_ORDER': 'Let\'s track your order first',
        'LET_US_CHECK_ELIGIBILITY': 'Let us check if you are eligible for instant store-credits so you can order another item right away!',
        'LOCATE_MY_GIFT': 'LOCATE MY GIFT',
        'MEDIUM': "Medium",
        'MIXING_IS_NOT_SUPPORTED': 'Mixing is not supported, you must create a separate case for that',
        'MORE_ABOUT_OPTIONS_MODAL_TITLE': 'More about your options',
        'NEW': 'New item',
        'NEW_REQUEST': 'New Request',
        'NEXT': 'next',
        'NO_I_WOULD_NOT_KEEP_IT': 'No, I wouldn\'t keep it',
        'NO_IT_IS_UNUSABLE': 'No, it’s unusable',
        'NOTE': 'Note:',
        'NO_RESOLUTION_AVAILABLE': 'No resolution is available for this item at this time',
        'NO_RESOLUTION_ITEM': 'We couldn’t offer you an immediate resolution at this time. Submit your return request and our team will review your case',
        'OF': 'of',
        'OK': 'ok',
        'ONCE_CREDIT_RECEIVED_I_CAN_NOT_RETURN_VIA_STANDARD_RETURN_POLICY': 'Once store-credit is received, I can no longer return the relevant item(s) according to the store return policy.',
        'OLD': 'Original item',
        'ORDER_DETAILS': 'Order details',
        'ORDER_DETAILS_CONTENT': 'Refer to the order invoice for the following information',
        'ORDER_NUMBER_FIELD': 'ORDER NUMBER',
        'ORDER_NUMBER_TOOLTIP_DESCRIPTION': 'Your order number can be found in your order confirmation email.',
        'ORDER_NUMBER_TOOLTIP_TITLE': 'Order number',
        'OTHER': 'Other',
        'PERSONALIZED_OFFER_YOU_RECEIVED_BASED_ON_RETURN_SCORE': 'However, the personalized offer you received, which is based on an internal |return score| provides you instant store-credits which you can use just like cash to spend instantly, without the hassle of sending the items back and waiting for your refund.',
        'PHOTO': 'Photo',
        'PLEASE_EXPLAIN': 'Please explain so we could improve',
        'PLEASE_CHOOSE_FROM_OPTIONS': 'Please choose from the options below',
        'PROPOSITION_NOTE': 'One or more resolutions are disabled due to store policy conditions applicable to your return request',
        'EXTRA_LARGE': 'Extra Large',
        'INCLUDES_FEE': 'INCLUDES %s FEES',
        'INCLUDED_IN_DISCOUNT': 'Included in discount',
        'NOTE_TITLE': 'Pay Attention',
        'BXGY_REFUND_WARNING': 'The action you selected might cause you to lose the Buy X get Y (BxGy) discount this item was purchased with. The refund amount may be adjusted.',
        'BXGY_EXCHANGE_WARNING': 'The action you selected might cause you to lose the Buy X get Y (BxGy) discount this item was purchased with. Pricing may be adjusted depending on the exchanged item.',
        'BXGY_CHOSEN_ITEM_WARNING': 'The item(s) you have chosen to return were not part of the original discount code. You may be required to pay additional fees to complete the exchange.',
        'INCLUDES_FEE_DEFAULT': 'INCLUDES FEES',
        'EXCHANGE_WITH': 'What do you want to exchange it with?',
        'ERROR_IBAN': 'Error IBAN. Please check',
        'PLEASE_CHOOSE_ONE_OPTION': 'Please choose one option',
        'PLEASE_ENTER_IBAN': 'Please enter your IBAN',
        'PLEASE_KEEP_RMA_NUMBER': 'Please keep the RMA number below for future reference',
        'PLEASE_WRITE_EXPLANATION': 'Please write your explanation and wishes',
        'PLEASE_WRITE_RETURN_REASON': 'Please write return reason (optional)',
        'POSTAL_CODE': 'POSTAL CODE',
        'PREVIEW_MODE': 'Preview mode',
        'PREVIEW_MODE_TOOLTIP_DESCRIPTION': 'This is a preview of what your customers will see. Feel free to play around, your actions will have no effect.',
        'PRODUCT_DOES_NOT_PERFORM': 'Product does not perform as promised',
        'PRINT_LABEL': 'Print label',
        'QUALITY_IS_NOT_AS_EXPECTED': 'Quality is not as expected',
        'READ_MORE': 'Read More',
        'READ_LESS': 'Read Less',
        'REASON': 'Reason:',
        'RED': 'Red',
        'REGION_STATE': 'REGION/STATE',
        'RESOLUTION': 'Resolution',
        'RETURN_GIFTS': 'Return Gifts',
        'RETURNING_A_GIFT': 'Returning a gift',
        'RETURNING_YOUR_ORDER': 'Returning your order',
        'RETURN_AND_EXCHANGE_ASSISTANT': 'Return & Exchange Assistant',
        'RETURN_CREDITS': 'Return Credits',
        'RETURN_REASON_INFO': 'Return Reason info',
        'RETURN_SCORE': 'Return score',
        'RETURN_SCORE_MODAL_DESCRIPTION': 'Return Score is an internal number that is calculated from hundreds of parameters. The higher your score, the better your alternative offers will be when making a return request.',
        'SHOP_NOW': 'Shop now',
        'OUT_OF': 'out of',
        'RMA_APPROVED': 'Approved request',
        'RMA_DONE': 'Request done',
        'RMA_NUMBER': '',
        'RMA_CODE': '%s',
        'RMA_PENDING_APPROVAL': 'Pending request',
        'RMA_REJECTED': 'Rejected request',
        'SELECT': 'Select',
        'SELECTED_ITEMS': 'Select items',
        'SHIP_IT_LATER': 'Ship it later',
        'SHIPPING_DETAILS': 'Shipping Details',
        'SHIPPING_NOTES': 'Shipping notes:',
        'SHOP_RIGHT_NOW': 'Shop right now',
        'SIT_TIGHT_WHILE_WE_FINALIZE_OFFER': 'Sit tight while we finalize your offer!',
        'SIZE_IS_NOT_AS_EXPECTED': 'Size is not as expected',
        'VIEW_SIZE_GUIDE': 'View Size Guide',
        'SKIP': 'skip',
        'SMALL': 'Small',
        'SORRY_ABOUT_THAT': 'Sorry about that...',
        'STORE_AND_OTHERS_CAN_USE_INFO': 'I understand the store, or any related parties, may use this information to improve services and investigate return request abuse.',
        'STORE_CREDIT': 'Store credit',
        'STORE_CREDITS_WILL_EXPIRE': 'I confirm this store-credit will expire in 30 days and will no longer be available for me to use.',
        'STANDARD_RETURN_POLICY': 'Standard Return Policy',
        'SUMMARY': 'Summary',
        'TAKE_ME_OUT': 'I am sure, take me out',
        'THANK_FOR_LETTING_US_KNOW_CHANGED_MIND': 'Thanks for letting us know you changed your mind - that’s fine!',
        'THIS_IS_WHAT_YOUR_ORDER': 'This is what you ordered. Would you like to cancel this order?',
        'TOO_LARGE_LOOSE': 'Too large / loose',
        'TOO_LONG': 'Too long',
        'TOO_SHORT': 'Too short',
        'TOO_SMALL_TIGHT': 'Too small / tight',
        'TOTAL': 'Total',
        'UNEXPECTED_WEAR_AND_TEAR': 'Unexpected wear & tear',
        'UPLOAD_ITEM_PICTURE': 'Upload item picture',
        'USE_TO_NOTIFY_RETURN': 'We will use it to notify you about your return request',
        'WE_ARE_SENDING_CONFIRMATION_EMAIL': 'We\'re sending you a confirmation email with the next steps instructions right now. Please make sure to check your promotion/spam folder just in case, as further updates will be communicated by email.',
        'WE_ARE_SORRY_ITEM_DOES_NOT_FIT_WHAT_PROBLEM': 'We’re sorry the item doesn’t fit. Can you please tell us what the problem is?',
        'WE_ARE_SORRY_ITEM_DOES_NOT_MEET_EXPECTATIONS_HOW_IS_IT_DIFFERENT': 'We’re sorry the item doesn’t meet your expectations. How is it different from what you expected?',
        'WE_ARE_SORRY_ITEM_DOES_NOT_MEET_QUALITY': 'We are sorry your item does not meet your quality expectations. What went wrong?',
        'WE_ARE_SORRY_ITEM_IS_NOT_AS_DESCRIBED_UPLOAD_PICTURE': 'We are sorry that the item you received is not as described. Would you please upload a picture of the item?',
        'WE_ARE_SORRY_ITEM_IS_NO_LONGER_USABLE_UPLOAD_PICTURE': 'We are sorry that the item is no longer usable due to the delay. We will try to compensate you for this. Can you upload a picture of the item?',
        'WE_ARE_SORRY_FOR_THE_DELAY': 'We\'re sorry for the delay. Can you please let us know how many days late it arrived per your expectation?',
        'WE_ARE_SORRY_PARCEL_IS_DAMAGED_UPLOAD_PICTURE': 'We are sorry the parcel you received is damaged. Would you please upload a picture of the damage?',
        'WE_ARE_SORRY_YOU_RECEIVED_DAMAGED_ITEM_UPLOAD_PICTURE': 'We are sorry that you received a damaged item. Would you upload a picture of the item? Please make sure the issues are clearly visible in the picture',
        'WE_ARE_SORRY_YOU_RECEIVED_WRONG_ITEM_UPLOAD_PICTURE': 'We are sorry that you received the wrong item. Would you please upload a picture of the item you received?',
        'WE_COULD_NO_OFFER_YOU_AT_THIS_TIME': 'We could not offer you an immediate resolution at this time. If you wish to complete your return using the standard policy, please |click here|.',
        'WE_COULD_NO_OFFER_YOU_AT_THIS_TIME_REFERENCE': 'Your request is not supported by our |Return Policy.| Contact |Customer Support| if you have any questions.',
        'WE_HAVE_SENT_CONFIRMATION_EMAIL': 'We have sent you a confirmation email with instructions about how to proceed with the relevant item(s).',
        'WHAT_IS_YOUR_METHOD': 'What do you want to do with it?',
        'WHAT_IS_YOUR_REASON_FOR_RETURNING': 'What is your reason for returning this item?',
        'WHICH_ITEMS_YOU_WANT_TO_RETURN': 'Which item(s) would you like to return / exchange?',
        'WHY_ARE_YOU_CANCELING_YOUR_ORDER': 'Why are you canceling your order?',
        'WHY_DO_YOU_WANT_TO_REPLACE': 'Why do you want to replace this product and what do you want instead?',
        'WHY_DO_WE_NEED_THIS_PICTURE': 'Why do we need this picture?',
        'WHY_DO_WE_NEED_THIS_PICTURE_ANSWER': 'Uploading a picture is optional, but it can help you get a greater Return Score. The higher your Return Score is, the better offers you might get for this order and future ones.',
        'WOULD_YOU_PROVIDE_INFO_ABOUT_RETURN_REASON': 'Would you provide us information about the return reason so we can get better?',
        'WOULD_YOU_TAKE_PICTURE_WE_CAN_GET_BETTER': 'Would you please take a picture of the item, so we can get better for future orders?',
        'WOULD_YOU_UPLOAD_ITEM_PICTURE': 'Would you please upload a picture of the item you received? (optional)',
        'YES_I_WOULD_NOT_MIND_KEEPING_IT': 'Yes, I wouldn’t mind keeping it',
        'YES_IT_IS_STILL_USABLE': 'Yes, it’s still usable',
        'YOU_ARE_CANCELING_ORDER': 'You are canceling the following order',
        'YOUR_CONTACT_DETAILS': 'Your contact details',
        'YOUR_RETURN_REQUEST': 'Your return request',
        'YOUR_ORDER_WAS_NOT_SHIPPED': 'Your order was not shipped yet',
        'YOUR_SHIPPING_ADDRESS': 'Your shipping address',
        'YOU_HAVE_JUST_RECEIVED': 'You have just received',
        'ITEM_ACTION_EXCHANGE': 'Exchange',
        'ITEM_ACTION_NO_EXCHANGE': 'No instant exchange available',
        'ITEM_ACTION_MANUAL_EXCHANGE': 'Exchange by customer service',
        'ITEM_ACTION_RETURN': 'Return',
        'ITEM_ACTION_SKIP': 'Skip this item (do not return it now)',
        'SHIPPING_INSTRUCTIONS': 'Shipping Instructions',
        'SHIPPING': "Shipping:",
        'TAX': "Tax:",
        'TOTAL_PAID': 'Total:',
        'QR_CODE': "Get Label Broker code",
        'PRICE_DIFFERENCE': "Price difference",
        'SUBTOTAL': "Subtotal",
        'PRICE_MAY_DIFFER': "Price may differ",
        'SOON_YOU_WILL_RECEIVE_EMAIL_GIFT_CARD': 'You should receive another email with the gift card code, that you can use at the checkout',
        'YOU_HAVE_JUST_RECEIVED_GIFT_CARD': 'You have just received a',
        'RESOLUTION_DESCRIPTION': 'Resolution Description:',
        'ADDITIONAL_COSTS': 'Additional costs:',
        'ESTIMATED_REFUND': 'Estimated refund',
        'OVERALL': 'Overall',
        'INCLUDE_ZERO_FREE': 'Includes $0 fee',
        'SHIPPING_COSTS': 'Shipping costs',
        'UPLOAD_UP_TO_4_IMAGES': 'Upload up to 4 images',
        'SORRY_MESSAGE_TITLE': 'Sorry',
        'SORRY_MESSAGE_TEXT': 'We couldn’t offer you an immediate resolution at this time. Submit your return request and our team will review your case.',
        'SORRY_MESSAGE_TEXT_REFERENCE': 'Your request for this item is not supported by our |Return Policy|. Contact |Customer Support| if you have any questions.',
        'CLOSEST_LOCATION': 'Closest location:',
        'DROPOFF_LOCATIONS_TITLE': 'View drop-off locations closest to you',
        'CLEAR_ACTION_SELECTION': 'Clear Selection'
      },
      // 'return-assistant-faq': {
      //   'FAQ1' : 'How can you resolve my return request?',
      //   'FAQ1_ANSWER' : 'Easy. We ask you which items you want to return and several related questions. Based on your information, our algorithm gives you the best alternative.  Instead of waiting for a refund, you will get offers to resolve your return request for store-credits.',
      //   'FAQ2' : 'What is a store-credit?',
      //   'FAQ2_ANSWER' : 'Store credit is just like cash. You can use it on checkout, and it does not replace any promo or store discount.  Again, it is just like real money.',
      //   'FAQ3' : 'Which return alternatives you have?',
      //   'FAQ3_ANSWER' : 'Usually, we offer you store-credits for 1. Keeping the item, which means you should not ship it back ever. 2. Ship back later - you receive store-credits to buy something new immediately and need to ship back the products within two weeks. Donate it - donate the item to someone less fortunate.',
      //   'FAQ4': 'I do not want store-credits; I want a refund.',
      //   'FAQ4_ANSWER' : 'Our return alternatives based on store-credits are optional. You can always proceed to a standard return process according to the store return policy.',
      //   'FAQ5' : 'So, can I get store-credits every time I want to make return?',
      //   'FAQ5_ANSWER' : 'You\'re always welcome to try! Based on your internal "Return Score," we offer you the best alternatives possible. ',
      //   'FAQ6' : 'What is a "Return score"?',
      //   'FAQ6_ANSWER' : 'A return score is an internal number based on hundreds of parameters. The rule of thumb is, the more you buy, and the more honest you are about your return request, the higher Return Score you gain.  ',
      //   'FAQ7' : 'This is not fair. My return score is low because the product I got were wrong.',
      //   'FAQ7_ANSWER' : 'Our algorithm should consider all of the parameters. However, we can always be wrong, and it depends on objective parameters as well. In any case, you can still proceed to the return process based on the store return policy. ',
      //   'FAQ8' : 'Tell me how to get the best return score',
      //   'FAQ8_ANSWER' : 'Simple. Be fair and noble. If you are an honest customer who requests a return only when it is really needed, your score will be high. ',
      //
      // },
      'return-reason': {
        'ARRIVED_TOO_LATE': "Arrived too late",
        'CHANGED_MY_MIND': "Changed my mind",
        'DOES_NOT_FIT': "Does not fit",
        'ITEM_IS_DAMAGED': "Item is damaged",
        'NOT_AS_DESCRIBED': "Not as described",
        'NOT_WHAT_I_EXPECTED': "Not what I expected",
        'PARCEL_DAMAGED_ON_ARRIVAL': "Parcel damaged on arrival",
        'POOR_QUALITY': "Poor quality",
        'RECEIVED_WRONG_ITEM': "Received wrong item",
        'OTHER': 'Other'
      },
      'return-portal-errors': {
        'EMAIL_EMPTY': 'Email should not be empty.',
        'ORDER_ID_EMPTY': 'Order number should not be empty.',
        'ORDER_NOT_FOUND': 'The order was not found - please try again',
        'ALREADY_REFUNDED': 'The order has already been refunded',
        'ALREADY_CLOSED': 'A return credit offer has already been accepted or declined for this order',
        'NOT_FULFILLED': 'The order has not been fulfilled yet. To cancel it, please contact customer support',
        'TOO_MANY_OFFERS': 'Too many return credit offers have already been made for this order. You can still process a return according to the return policy',
        'POLICY_EXPIRED': 'The return period has expired for this order',
        'ERR_RETURN_VALUE_TOO_HIGH': 'The total value of the items being returned is too high for automatic handling.',
        'UNKNOWN': 'Oops... Something went wrong. Please try again',
      },
      resolutions: {
        'RESOLUTION_NAME1': 'Ship it later',
        'RESOLUTION_DESC1': 'Shop now with instant credits, and ship the item later, when you get to it',
        'RESOLUTION_NAME2': 'Keep the item',
        'RESOLUTION_DESC2': 'Shop now with instant credits, and in addition, you get to keep the original item!',
        'RESOLUTION_NAME3': 'Donate the item',
        'RESOLUTION_DESC3': 'Shop now with instant credits, when you\'re done, please donate the original item to someone who needs it more than you',
        'RESOLUTION_NAME4': 'Refund to payment method',
        'RESOLUTION_DESC4': 'Submit a refund request, wait for approval, ship the item, and get a refund to your payment method after the item is received and verified.',
        'RESOLUTION_NAME5': 'Refund to store credit',
        'RESOLUTION_DESC5': 'Submit a request, wait for approval, ship the item, and get store credit after the item is received and verified.',
        'RESOLUTION_NAME6': 'Instant exchange',
        'RESOLUTION_DESC6': 'Exchange the item for the new item you selected',
        'RESOLUTION_NAME7': 'Refund to bank account',
        'RESOLUTION_DESC7': 'Submit a request, wait for approval, ship the item....',
        'RESOLUTION_NAME8': 'Exchange by customer support',
        'RESOLUTION_DESC8': 'Exchange the item for the new....',
        'CUSTOM_RESOLUTION_NAME_2': 'Refund to Payment',
        'CUSTOM_RESOLUTION_DESCRIPTION_2': 'Refund Initiated immediately',
      },
      'return-methods': {
        'RETURNMETHOD_NAME0': 'No shipment required',
        'RETURNMETHOD_DISPLAYNAME0': 'No shipment required',
        'RETURNMETHOD_DESC0': 'You get to keep the item, no shipment required',
        'RETURNMETHOD_NAME1': 'Ship with any carrier',
        'RETURNMETHOD_DISPLAYNAME1': 'Ship with any carrier',
        'RETURNMETHOD_DESC1': 'Ship the item back with any carrier of your choice',
        'RETURNMETHOD_NAME2': 'Ship with pre-paid label',
        'RETURNMETHOD_DISPLAYNAME2': 'Ship back with pre-paid label',
        'RETURNMETHOD_DESC2': 'Ship the item back using a pre-paid label',
        'RETURNMETHOD_NAME3': 'Return to store location',
        'RETURNMETHOD_DISPLAYNAME3': 'Return at a physical store location',
        'RETURNMETHOD_DESC3': 'Return the item at a physical store location near you',
        'RETURNMETHOD_DISPLAYNAME242': 'Drop off at any Happy Returns® Location<br/><br/>No Box or label needed, refund initiated immedately.',
      },
      'tracking-portal': {
        /** Static titles */
        'TRACKING_PORTAL_TITLE': 'Order Tracking Portal',
        'LETS_TRACK_ORDER': 'Let’s find your order first',
        'LATEST_UPDATE': 'Latest update',
        'ESTIMATED_DELIVERY': 'Estimated delivery',
        'STATUS': 'Status',
        'DETAILS': 'Details',
        'TRACKING_NUMBER_TITLE': 'Tracking Number',
        'ORDER_NUMBER_TITLE': 'Order Number',
        'NAME_DETAILS': 'Name',
        'ADDRESS_DETAILS': 'Address',
        'SHIPMENT_CONTENT': 'Shipment Content',
        'ITEM': 'item',
        'ITEMS': 'items',
        'HISTORY': 'History',
        'NOTIFICATION_OPT_OUT': 'I don\'t want to get updates for this shipment',
        'CARD_SHOW_MORE': 'Show More',
        'CARD_SHOW_LESS': 'Show Less',
        'TRACKING_NUMBER_NOT_AVAILABLE': "Not available",
        'UNFULFILLED_NOTIFICATION_HEADER': 'Unfulfilled Items',
        'UNFULFILLED_NOTIFICATION_DESCRIPTION': 'The below items are unfulfilled and weren’t shipped yet.',
        'UNFULFILLED_BANNER_TEXT': 'Your order has |unfulfilled item(s)|',

        /** Error messages */
        'ERROR_FROM_TRACKER': 'Tracking data not found',
        'SHIPMENT_NOT_FOUND': 'Shipment not found',
        'NO_TRACKING_NUMBER': 'Tracking number not found',
        'CONTACT_CS': 'For more information, contact |customer support|.',
        'ORDER_CANCELLED': 'Order Cancelled',

        'SHIPMENT_NOT_FOUND_DESCRIPTION': 'Failed to retrieve tracking information',
        'NO_TRACKING_NUMBER_DESCRIPTION': 'Order was fulfilled without providing tracking number',
        'ORDER_CANCELLED_DESCRIPTION': 'Your order was cancelled.',
        'ERROR_FROM_TRACKER_DESCRIPTION': 'Couldn\'t get tracking data.',

        /** Status and description */
        'PENDING_SHIPMENT': 'Ordered',
        'LABEL_CREATED': 'Packed',
        'SHIPPED': 'In transit',
        'OUT_FOR_DELIVERY': 'Out for Delivery',
        'RECEIVED': 'Delivered',
        'REJECTED': 'Failure to deliver',
        'ERROR': 'Error',
        'TRACKING_DESCRIPTION_PENDING_SHIPMENT': 'Order confirmed, shipment is pending',
        'TRACKING_DESCRIPTION_LABEL_CREATED': 'The shipment is ready, waiting for carrier',
        'TRACKING_DESCRIPTION_SHIPPED': 'The carrier is handling the shipment',
        'TRACKING_DESCRIPTION_OUT_FOR_DELIVERY': 'The shipment is on its way to the destination',
        'TRACKING_DESCRIPTION_RECEIVED': 'Shipment arrived and delivered successfully',
        'TRACKING_DESCRIPTION_REJECTED': 'Could not complete the delivery for some reason',
        'TRACKING_DESCRIPTION_ERROR': 'Error occured',

        /** Status Descriptions */
        'NO_TRACKING_INFORMATION_RECEIVED_YET': 'No tracking information received yet',
        'THE_PACKAGE_IS_AWAITING_FOR_COURIER_PICKUP': 'The package is awaiting for courier pickup',
        'THE_PACKAGE_WAS_PICKED_UP_BY_THE_COURIER': 'The package was picked up by the courier',
        'THE_PACKAGE_ARRIVED_TO_A_SORTING_CENTER': 'The package arrived to a sorting center',
        'THE_PACKAGE_ARRIVED_TO_A_DELIVERY_FACILITY': 'The package arrived to a delivery facility',
        'THE_PACKAGE_ARRIVED_TO_THE_DESTINATION_COUNTRY': 'The package arrived to the destination country',
        'CUSTOM_CLEARANCE_COMPLETED': 'Custom clearance completed',
        'THE_PACKAGE_WAS_DISPATCHED': 'The package was dispatched',
        'THE_PACKAGE_WAS_DEPARTED_FROM_THE_AIRPORT': 'The package was departed from the airport',
        'THE_PACKAGE_IS_OUT_FOR_DELIVERY': 'The package is out for delivery',
        'THE_PACKAGE_IS_READY_FOR_COLLECTION': 'The package is ready for collection',
        'CUSTOMER_WAS_CONTACTED_BEFORE_FINAL_DELIVERY': 'Customer was contacted before final delivery',
        'THE_PACKAGE_DELIVERED_SUCCESSFULLY': 'The package delivered successfully',
        'THE_PACKAGE_PICKED_UP_BY_THE_RECEIVER': 'The package picked up by the receiver',
        'THE_PACKAGE_RECEIVED_AND_SIGNED_BY_RECEIVER': 'The package received and signed by receiver',
        'THE_PACKAGE_WAS_LEFT_AT_THE_FRONT_DOOR_OR_LEFT_WITH_YOUR_NEIGHBOUR': 'The package was left at the front door or left with your neighbour',
        'ADDRESS_WAS_NOT_FOUND_OR_DOES_NOT_EXIST': 'Address was not found / does not exist',
        'RECEIVER_WAS_NOT_HOME': 'Receiver was not home',
        'UNDELIVERED_DUE_TO_OTHER_REASONS': 'Undelivered due to other reasons',
        'THE_PACKAGE_WAS_UNCLAIMED': 'The package was unclaimed',
        'THE_PACKAGE_WAS_DETAINED_BY_CUSTOMS': 'The package was detained by customs',
        'THE_PACKAGE_WAS_DAMAGED_OR_LOST_DURING_DELIVERY': 'The package was damaged or lost during delivery',
        'ORDER_WAS_CANCELLED_BEFORE_COURIER_PICK_UP': 'Order was cancelled before courier pick up',
        'THE_PACKAGE_WAS_REFUSED_BY_THE_RECEIVER': 'The package was refused by the receiver',
        'THE_PACKAGE_HAS_BEEN_RETURNED_TO_THE_SENDER': 'The package has been returned to the sender',
        'THE_PACKAGE_IS_BEING_SENT_BACK_TO_THE_SENDER': 'The package is being sent back to the sender',
        'NO_TRACKING_INFORMATION_FOUND': 'No tracking information found',
      },
    },
  },
  htmls: {
    en: {
      assistant: {
        FIRST_PAGE_CONTENT: '<p>This is <sup>superscripted</sup> text.</p>',
      },
      'return-methods': {
        'RETURNMETHOD_INSTRUCTIONS0': null,
        'RETURNMETHOD_INSTRUCTIONS1': "Please press the <b> \"Print Label\" </b> button to download a PDF of your return shipping label.<br/>Print and use this shipping label when returning your item.",
        'RETURNMETHOD_INSTRUCTIONS2': null,
        'RETURNMETHOD_INSTRUCTIONS3': null,
        'RETURNMETHOD_QR_INSTRUCTION0': null,
        'RETURNMETHOD_QR_INSTRUCTION1': "<div><strong>DON’T HAVE A PRINTER</strong>&nbsp;</div><div>If you don’t have a printer, no worries. You can generate a USPS Label Broker® code to take to the Post Office and they will create a shipping label for you. Present your Label Broker code to the Retail Associate at the counter and they will handle the rest. To receive your Label Broker code, please press the button.&nbsp;</div>",
        'RETURNMETHOD_QR_INSTRUCTION2': null,
        'RETURNMETHOD_QR_INSTRUCTION3': null,
      },
    }
  },
};

let countries = [
  {
    code: 'PH',
    name: 'Philippines',
    provinces: [
      { code: 'Zambales', name: 'Zambales' },
      { code: 'Manila', name: 'Manila' }
    ]
  }
]

export const fetch = (init?: RequestInit) => {
  const body = JSON.parse(init?.body as string);
  const scopes = body?.scopes ?? [];
  const lang = body?.lang ?? 'en';
  let data = {
    strings: {
      [lang]: scopes.reduce((res, scope) => ({
        ...res,
        [scope]: translations.strings.en[scope],
      }), {}),
    },
    htmls: {
      [lang]: scopes.reduce((res, scope) => ({
        ...res,
        [scope]: translations.htmls.en[scope],
      }), {})
    },
    multipleItemsPerRequest: true,
    proxyPath: '/a/returns',
    hasQuestions: true,
    giftReturnsEnabled: true,
    request_customers_confirm_policy: false,
    customization: {
      order_tracking_show_eta: true,
      logo: null,
      font: 'Poppins',
      min_embedded_offset_top: 40,
      title_color: '242b33',
      text_color: '818181',
      input_text_color: '242b33',
      input_background_color: 'ffffff',
      button_text_color: 'ffffff',
      button_background_color: 'ff5450',
      sku_display: true,
    },
    countries
  }
  return { data };
};
