/**
 * At runtime use
 * window.httpService.toggleMode()
 * to change mode of api calls real|mock
 * window.httpService.forceMocked.push(pattern)
 * to mock specific api calls (pattern example: /case$/)
 */
import { FetchResponse, Mode } from './types';
import FetchService from './FetchService';
import MockService from './MockService';

const isMockModeEnabledFromUrl = () => (
  window.location.href.includes('mock=1')
);

export const isCancelFromUrl = () => (
  window.location.href.includes('cancel=1')
);

export const isLabelUrl = () => (
  window.location.href.includes('label=1')
);

export default class HttpService {
  private static instance: HttpService;
  private mode: Mode;
  private forcedModeFor: Record<Mode, (string | RegExp)[]> = {
    [Mode.API]: [],
    [Mode.MOCK]: [],
  };
  
  constructor() {
    this.mode = Mode.API;
    if (isMockModeEnabledFromUrl()) {
      this.mode = Mode.MOCK;
    }
  }
  
  static getInstance(): HttpService {
    if (!this.instance) {
      this.instance = new HttpService();
    }
    return this.instance;
  }

  public static navigateToMaintenancePage: () => void | undefined;
  
  static async fetch(url: string, init?: RequestInit): Promise<FetchResponse> {
    const httpService = this.getInstance();
    if ((httpService.mode === Mode.API && !httpService.forcedModeFor.mock.some(pattern => url.match(pattern))) ||
      httpService.forcedModeFor.api.some(pattern => url.match(pattern))
    ) {
      const response =  await FetchService.fetch(url, init);
      if (response.error === "503" && HttpService.navigateToMaintenancePage) {
        HttpService.navigateToMaintenancePage();
      }
      return response;
    }
    return MockService.fetch(url, init);
  }
  
  static async post(url: string, init?: RequestInit): Promise<FetchResponse> {
    return this.fetch(url, {
      ...init,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
    });
  }
  
  static async get(url: string, init?: RequestInit): Promise<FetchResponse> {
    return this.fetch(url, {
      ...init,
      method: 'get',
      headers: { 'Content-Type': 'application/json' },
    });
  }

  static async delete(url: string, init?: RequestInit): Promise<FetchResponse> {
    return this.fetch(url, {
      ...init,
      method: 'delete',
      headers: { 'Content-Type': 'application/json' },
    });
  }
  
  toggleMode(): Mode {
    if (this.mode === Mode.API) {
      this.mode = Mode.MOCK;
    } else {
      this.mode = Mode.API;
    }
    return this.mode;
  }
}

window.onload = () => {
  window.httpService = HttpService.getInstance();
};
