const data = {
  'token': 'dee97cb6740c659519a3e2d526334ffa',
  'note': null,
  'attributes': {},
  'original_total_price': 3500,
  'total_price': 3500,
  'total_discount': 0,
  'total_weight': 500,
  'item_count': 1,
  'items': [
    {
      'id': 35236827267227,
      'properties': null,
      'quantity': 1,
      'variant_id': 35236827267227,
      'key': '35236827267227:774dbaddb6da35d42edf4613dcd473db',
      'title': 'Cuffed Beanie - XS',
      'price': 3500,
      'original_price': 3500,
      'discounted_price': 3500,
      'line_price': 2975,
      'original_line_price': 3500,
      'total_discount': 0,
      'discounts': [],
      'sku': '',
      'grams': 500,
      'vendor': 'Fashion shop 7',
      'taxable': true,
      'product_id': 5437689757851,
      'product_has_only_default_variant': false,
      'gift_card': false,
      'final_price': 2975,
      'final_line_price': 2975,
      'url': '/products/cuffed-beanie?variant=35236827267227',
      'featured_image': {
        'aspect_ratio': 0.784,
        'alt': 'Cuffed Beanie',
        'height': 1110,
        'url': 'https://cdn.shopify.com/s/files/1/0447/2523/9963/products/pr3-2.jpg?v=1596013691',
        'width': 870
      },
      'image': 'https://cdn.shopify.com/s/files/1/0447/2523/9963/products/pr3-2.jpg?v=1596013691',
      'handle': 'cuffed-beanie',
      'requires_shipping': true,
      'product_type': '',
      'product_title': 'Cuffed Beanie',
      'product_description': 'Go sporty this summer with this vintage navy and white striped v-neck t-shirt from the Nike. Perfect for pairing with denim and white kicks for a stylish sporty vibe.',
      'variant_title': 'XS',
      'variant_options': [
        'XS'
      ],
      'options_with_values': [
        {
          'name': 'Size',
          'value': 'XS'
        }
      ],
      'line_level_discount_allocations': [],
      'line_level_total_discount': 0
    }
  ],
  'requires_shipping': true,
  'currency': 'USD',
  'items_subtotal_price': 2975,
  'cart_level_discount_applications': []
};

export const fetch = (init?: RequestInit) => {
  return { data };
}
