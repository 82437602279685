export enum PortalType {
    RETURN = "return",
    TRACKING = "tracking",
}

export enum PortalOrigin {
    SMS = "sms",
    EMAIL = "email",
    SHOPIFY_SHOP_APP_ORIGIN = "shopify_shop_app",
}

export function getPortalOriginByValue(value: string) : PortalOrigin | undefined {
    return Object.entries(PortalOrigin).find(([key, val]) => val === value)?.[1];
}
