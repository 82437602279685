import { FollowupQuesionTypes } from "../../stores/ReturnReasonsStore/types";

const data = {
  // 'error_code': 1,
  'order': {
    'customer_id': '3675882487971',
    'order_id': '2564799365283',
    'is_cancelled': false,
    'name': '#1009',
    'status': null,
    'store_currency_code': 'EUR',
    'payment_currency_code': 'ILS',
    'email_needed': false, // Set to true to go through the SetEmail screen
    'order_products': [
      {
        'image': {
          'alt': null,
          'src': 'https://www.rlmedia.io/is/image/PoloGSI/s7-1356810_lifestyle'
        },
        'product_id': '1',
        'shop_variant_id': '12345',
        'name': 'Classic Fit Plaid Oxford Shirt',
        'offer': 'Accepted',
        'sku': 'ABC1234',
        'quantity': 1,
        'price': '29.9',
        'fullPrice': '29.9 USD',
        'available_actions': ['return', 'exchange'],
        'request_image': 1,
        'variantOptions': [
          {name: 'Size', value: 'L'}, {name: 'Color', value: 'Red'}
        ],
        'variantTitle': null,
      },
      {
        'image': {
          'alt': null,
          'src': 'https://www.rlmedia.io/is/image/PoloGSI/s7-1356810_lifestyle'
        },
        'product_id': '1',
        'shop_variant_id': '12345',
        'name': 'Classic Fit Plaid Oxford Shirt',
        'offer': 'Accepted',
        'sku': 'ABC1234',
        'quantity': 1,
        'price': '29.9',
        'fullPrice': '29.9 USD',
        'available_actions': ['return', 'exchange'],
        'request_image': 1,
        'variantOptions': [
          {name: 'Size', value: 'L'}, {name: 'Color', value: 'Red'}
        ],
        'variantTitle': null,
      },
      {
        'image': {
          'alt': null,
          'src': 'https://www.rlmedia.io/is/image/PoloGSI/s7-1356810_lifestyle'
        },
        'product_id': '1',
        'shop_variant_id': '12345',
        'name': 'Classic Fit Plaid Oxford Shirt',
        'offer': 'Accepted',
        'sku': 'ABC1234',
        'quantity': 1,
        'price': '29.9',
        'fullPrice': '29.9 USD',
        'available_actions': ['return', 'exchange'],
        'request_image': 1,
        'variantOptions': [
          {name: 'Size', value: 'L'}, {name: 'Color', value: 'Red'}
        ],
        'variantTitle': null,
      },
      {
        'image': {
          'alt': null,
          'src': 'https://www.rlmedia.io/is/image/PoloGSI/s7-1151197_lifestyle'
        },
        'product_id': '2',
        'shop_variant_id': '23456',
        'name': 'The Iconic Oxford Shirt - All Fits',
        'sku': '',
        'quantity': 1,
        'price': '29.9',
        'fullPrice': '29.9 USD',
        'available_actions': ['return']
      },
      {
        'image': {
          'alt': null,
          'src': 'https://www.rlmedia.io/is/image/PoloGSI/s7-1303426_lifestyle'
        },
        'product_id': '3',
        'shop_variant_id': '34567',
        'name': 'Garment-Dyed Oxford Shirt - All Fits',
        'sku': 'PJP656',
        'quantity': 1,
        'price': '29.9',
        'fullPrice': '29.9 USD',
        'status': 'partially-eligible',
        'missing_resolutions': ['refund']
      },
      {
        'image': {
          'alt': null,
          'src': 'https://www.rlmedia.io/is/image/PoloGSI/s7-1356811_lifestyle'
        },
        'product_id': '4',
        'shop_variant_id': '45678',
        'name': 'Oxford Short-Sleeve Shirt - All fits',
        'offer': 'Declined',
        'sku': '',
        'quantity': 1,
        'price': '29.9',
        'fullPrice': '29.9 USD',
        'status': 'partially-eligible',
        'missing_resolutions': ['refund']
      },
      {
        'image': {
          'alt': null,
          'src': 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Blue_Tshirt.jpg/220px-Blue_Tshirt.jpg'
        },
        'product_id': '5',
        'shop_variant_id': '555555',
        'name': 'A blue T-shirt - All Fits',
        'sku': '',
        'quantity': 1,
        'price': '19.9',
        'fullPrice': '19.9 USD',
        'available_actions': ['manual-exchange'],
        'request_image': 0
      },
    ],
    'shipments': [
    {
      'shipment_id': "abcd",
      'items': [{'lineItemid': '1', 'quantity': 3}, {'lineItemid': '2', 'quantity': 1}]
    }, 
    // {
    //   'shipment_id': "abcd",
    //   'items': [{'lineItemid': '5', 'quantity': 1}]
    // }, 
    ],
    'shipment_customer_name': 'Johnny Dough',
    'shipment_customer_address': '5th ave. 1234, New York City, NY, USA',
    'tracking_notification': true,
  },
  'return_reasons': [
    {
      'return_reason_id': 1,
      'name': 'ITEM_IS_DAMAGED',
    },
    {
      'return_reason_id': 2,
      'name': 'RECEIVED_WRONG_ITEM'
    },
    {
      'return_reason_id': 3,
      'name': 'CHANGED_MY_MIND'
    },
    {
      'return_reason_id': 4,
      'name': 'NOT_WHAT_I_EXPECTED'
    },
    {
      'return_reason_id': 5,
      'name': 'DOES_NOT_FIT'
    },
    {
      'return_reason_id': 6,
      'name': 'NOT_AS_DESCRIBED'
    },
    {
      'return_reason_id': 7,
      'name': 'ARRIVED_TOO_LATE'
    },
    {
      'return_reason_id': 8,
      'name': 'POOR_QUALITY'
    },
    {
      'return_reason_id': 9,
      'name': 'PARCEL_DAMAGED_ON_ARRIVAL'
    },
    {
      'return_reason_id': 10,
      'name': 'OTHER'
    }
  ],
  'case_id': 15,
  'contact_details': {
    'first_name': 'John',
    'last_name': 'Doe',
    'email_address': 'john@doe.com'
  }
};

export const fetch = (init?: RequestInit) => {
  return { data };
}
