import { FetchResponse } from './types';
import { transformCodeToMessage } from '../utils/errors';
import { isInStoreExchangesModeEnabledFromUrl, isTestModeEnabledFromUrl } from '../utils/helpers';

export default class FetchService {

  static withIsTest(init?: RequestInit) {
    return init ? {
      ...init,
      body: init.body ? JSON.stringify({
        ...JSON.parse(init.body as string),
        is_test: isTestModeEnabledFromUrl() || isInStoreExchangesModeEnabledFromUrl(),
      }) : init.body,
    } : init;
  }

  static async fetch(url: string, init?: RequestInit): Promise<FetchResponse> {
    try {
      if (JSON.parse(API_DEBUG)) {
        url += "?XDEBUG_SESSION_START=PHPSTORM";
      }
      const response = await fetch(url, this.withIsTest(init));
      if (response.status === 503) { //Added for Downtime
        return {data: null, error: "503"};
      }
      const data = await response.json();
      if (response.ok && `${response.status}`[0] === '2') {
        return { data };
      }
      return { data: null, error: transformCodeToMessage(data?.error_code), errors: data?.errors };
    } catch (e) {
      return { data: null, error: transformCodeToMessage() };
    }
  }
}
