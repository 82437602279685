import { RootStore } from '../stores/RootStore/RootStore';

export interface Stores {
  rootStore: RootStore;
}

export enum FetchStatus {
  UNKNOWN = 'unknown',
  PENDING = 'pending',
  DONE = 'done',
  ERROR = 'error',
}

export interface FetchState {
  status: FetchStatus;
  error?: string;
}

export enum ERROR_CODE {
  ORDER_NOT_FOUND = 1,
  ALREADY_CLOSED = 2,
  ALREADY_REFUNDED = 3,
  NOT_FULFILLED = 4,
  TOO_MANY_OFFERS = 5,
  POLICY_EXPIRED = 6,
  CUSTOMER_NOT_FOUND = 7,
  INVALID_ARGUMENTS = 8,
  CASE_NOT_FOUND = 9,
  WRONG_CUSTOMER = 10,
  WRONG_ORDER = 11,
  PRODUCT_NOT_FOUND = 12,
  ERR_SHOP_NOT_FOUND = 13,
  ERR_RETURN_VALUE_TOO_HIGH = 14,
  ERR_ORDER_ALREADY_CANCELLED = 15,
  ERR_ORDER_ALREADY_PENDING_FOR_CANCELLATION = 16,
  ERR_PAYMENT_PRE_AUTHORIZATION_FAILED = 18,
}

export enum RMA_STATUS {
  PENDING_APPROVAL = 0,
  APPROVED = 1,
  REJECTED = 2,
  DONE = 3,
}

export enum MEDIA_OPTION {
    ONLY_IMAGE = 'ONLY_IMAGE',
    ONLY_VIDEO = 'ONLY_VIDEO',
    IMAGE_AND_VIDEO = 'IMAGE_AND_VIDEO',
}
