const data = {
  'faq': [
    {
      'question': 'FAQ1',
      'answer': 'FAQ1_ANSWER',
      'weight': 1
    },
    {
      'question': 'FAQ2',
      'answer': 'FAQ2_ANSWER',
      'weight': 2
    },
    {
      'question': 'FAQ3',
      'answer': 'FAQ3_ANSWER',
      'weight': 3
    },
    {
      'question': 'FAQ4',
      'answer': 'FAQ4_ANSWER',
      'weight': 4
    },
    {
      'question': 'FAQ5',
      'answer': 'FAQ5_ANSWER',
      'weight': 5
    },
    {
      'question': 'FAQ6',
      'answer': 'FAQ6_ANSWER',
      'weight': 6
    },
    {
      'question': 'FAQ7',
      'answer': 'FAQ7_ANSWER',
      'weight': 7
    },
    {
      'question': 'FAQ8',
      'answer': 'FAQ8_ANSWER',
      'weight': 8
    },
  ]
};

export const fetch = (init?: RequestInit) => {
  return { data };
}
