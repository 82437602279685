const dataArrABCD = [{
    trackingNumber: "TR1234",
    // trackingUrl: "#",
    status: 4,
    // error: "TRACKER_NOT_EXISTS",
    status_description: 'THE_PACKAGE_WAS_LEFT_AT_THE_FRONT_DOOR_OR_LEFT_WITH_YOUR_NEIGHBOUR',
    status_text: 'left front door',
    carrier: "USPS Ignoring TM Logo URL",
    carrierCode: "usps",
    carrierLogoUrl: "https://s.trackingmore.com/images/icons/express/companylogo/21050.jpg",
    estimatedDeliveryDate: '2023-06-10T16:00:00',
    trackingHistory: [
        // {
        //     status: 3,
        //     status_description: 'THE_PACKAGE_WAS_LEFT_AT_THE_FRONT_DOOR_OR_LEFT_WITH_YOUR_NEIGHBOUR',
        //     status_text: 'The package was left at the front door or left with your neighbour', 
        //     location: "5th ave. 1234, New York City, NY, USA",
        //     date: '2023-06-10T20:00:00'
        // },
        // {
        //     status: 4,
        //     status_description: 'RECEIVER_WAS_NOT_HOME',
        //     status_text: 'The package was left at the front door or left with your neighbour', 
        //     location: "5th ave. 1234, New York City, NY, USA",
        //     date: '2023-06-10T16:00:00'
        // },
        {
            status: 2,
            status_description: 'THE_PACKAGE_WAS_PICKED_UP_BY_THE_COURIER',
            status_text: 'The package was left at the front door or left with your neighbour', 
            location: "Chicago, IL, USA",
            date: '2023-06-10T12:00:00'
        },
        {
            status: 10,
            status_description: 'THE_PACKAGE_IS_READY_FOR_COLLECTION',
            status_text: 'The package was left at the front door or left with your neighbour', 
            location: "Austin, TX, USA",
            date: '2023-06-10T10:00:00'
        },
        {
            status: 10,
            status_description: 'THE_PACKAGE_IS_READY_FOR_COLLECTION',
            status_text: 'The package was left at the front door or left with your neighbour', 
            location: "Austin, TX, USA",
            date: '2023-06-10T10:00:00'
        },
        {
            status: 10,
            status_description: 'THE_PACKAGE_IS_READY_FOR_COLLECTION',
            status_text: 'The package was left at the front door or left with your neighbour', 
            location: "Austin, TX, USA",
            date: '2023-06-10T10:00:00'
        },
        {
            status: 0,
            status_description: 'NO_TRACKING_INFORMATION_RECEIVED_YET',
            status_text: 'The package was left at the front door or left with your neighbour', 
            location: "Test location, USA",
            date: '2023-06-09T18:00:00'
        },
    ]
}, 
];

const dataArrEFGH = [{
    trackingNumber: "TREFGH1234",
    trackingUrl: "#",
    status: 3,
    status_description: 'RECEIVER_WAS_NOT_HOME',
    status_text: 'Reciever was not home',
    carrier: "USPS With Logo URL",
    carrierCode: "notReallyUsps",
    carrierLogoUrl: "https://s.trackingmore.com/images/icons/express/companylogo/21050.jpg",
    // estimatedDeliveryDate: '2023-07-15T16:00:00',
    trackingHistory: [
        {
            status: 3,
            status_description: 'THE_PACKAGE_WAS_LEFT_AT_THE_FRONT_DOOR_OR_LEFT_WITH_YOUR_NEIGHBOUR',
            status_text: 'The package was left at the front door or left with your neighbour', 
            location: "5th ave. 1234, New York City, NY, USA",
            date: '2023-06-10T20:00:00'
        },
        {
            status: 4,
            status_description: 'RECEIVER_WAS_NOT_HOME',
            status_text: 'The package was left at the front door or left with your neighbour', 
            location: "5th ave. 1234, New York City, NY, USA",
            date: '2023-06-10T16:00:00'
        },
        {
            status: 2,
            status_description: 'THE_PACKAGE_WAS_PICKED_UP_BY_THE_COURIER',
            status_text: 'The package was left at the front door or left with your neighbour', 
            location: "Chicago, IL, USA",
            date: '2023-06-10T12:00:00'
        },
        {
            status: 10,
            status_description: 'THE_PACKAGE_IS_READY_FOR_COLLECTION',
            status_text: 'The package was left at the front door or left with your neighbour', 
            location: "Austin, TX, USA",
            date: '2023-06-10T10:00:00'
        },
        {
            status: 0,
            status_description: 'NO_TRACKING_INFORMATION_RECEIVED_YET',
            status_text: 'The package was left at the front door or left with your neighbour', 
            location: "Test location, USA",
            date: '2023-06-09T18:00:00'
        },
    ]
}, 
];


const data = {
    "abcd": dataArrABCD,
    "efgh": dataArrEFGH,
    "empty": [
        
    ],
};

export const fetch = (init?: RequestInit) => {

    const body = JSON.parse(init?.body as string ?? null);
    if (body) {
        return { data: data[body.shipment_id] };
    }
    return { data };
}
