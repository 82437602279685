import { FetchResponse } from './types';
import { transformCodeToMessage } from '../utils/errors';

export default class MockService {
  static async fetch(url: string, init?: RequestInit): Promise<FetchResponse> {
    let fileName = url.slice(url.search(/[^\/]\/[^\/]/) + 2).replace(/\//g, '_');
    if(url === COUNTRIES_URL) {
      fileName = 'countries.ts';
    }

    const { fetch } = require(`./mocks/${fileName}`);
    const { data } = fetch(init);
    if (!data?.error_code) {
      return { data }
    }
    return { data: null, error: transformCodeToMessage(data?.error_code) };
  }
}
