export const IS_ISOLATION_MODE_ENABLED_FLAG = 'returnGO_isShadowDOMEnabled';
export const ISOLATION_MODE_WRAPPER_ID = 'ReturngoPortalShadowDOM';
export const ISOLATION_MODE_WRAPPER_SELECTOR = `#${ISOLATION_MODE_WRAPPER_ID}`;

export const isShadowDOMSupported = () => !!document.head.attachShadow;
export const isIsolationModeEnabled = () =>
    isShadowDOMSupported() && !!window[IS_ISOLATION_MODE_ENABLED_FLAG];

export const setIsolationModeIsEnabled = (isEnabled: boolean) =>
  window[IS_ISOLATION_MODE_ENABLED_FLAG] = isEnabled;

export const addCSSToIsolatedPortal = (css: string) => {
    const customStyleTag = document.createElement('style');
    customStyleTag.innerHTML = css;
    var isolatedAppCheckIterations = 0;
    var isolatedAppCheckInterval = setInterval(function() {
      const container = document.querySelector(ISOLATION_MODE_WRAPPER_SELECTOR) as HTMLElement;
      isolatedAppCheckIterations++;
      if (container) {
        container.shadowRoot.appendChild(customStyleTag);
        /* 
         * Unhide shadow dom container which should be hidden until styles are
         * added
         */
        container.setAttribute('style', "display: block !important;");
        clearInterval(isolatedAppCheckInterval);
        return;
      }

      if (isolatedAppCheckIterations >= 5) {
        clearInterval(isolatedAppCheckInterval);
      }
    }, 100);
}
